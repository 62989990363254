import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { RoutePage } from "../shared/route-page";

export class PageSources extends RoutePage {
  renderContent() {
    return html`
    
    <p>
      We're doing everything to make it actionable, but we can't do everything
      ourself.
    </p>
    <p>
      These are some more amazing sources of information that can help you get
      started, or deepen your knowledge of Web Component based web
      development.
    </p>

    `;
  }

  renderSingleChild(child) {
    return html`<a
      title="${child.title}"
      class="${child.img ? 'img-tile' : ''}"
      style="${child.img ? `--img: url(${child.img})` : '' }"
      rel="noopener"
      ,
      target="_blank"
      href="${child.url}"
      >
      ${this.renderIcon(child)}
      <span>${child.title}</span>
      </a>`;
  }

  getChildren() {
    const children = app.cms.list("source");
    
    children.push({
      title: "Have more Sources?",
      url: "mailto:info@pureweb.dev?subject=Sources",
      icon: "plus"
    });
    return children;
  }

  
}
