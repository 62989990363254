import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PagePureManifesto extends RoutePage {
  renderContent() {
    return html`
      <p>
        The Pure Manifesto is a set of priorities written by Fractional CTO Marc
        van Neerven, defining a modern, purely web-standards-based, approach to
        web development.
      </p>

      <menu class="sumup large fly-in">
        <li>
          Start with pure HTML (preferably semantic constructs)
          <small>...and leverage CSS3 fully</small>
        </li>
        <li>
          Use Progressive Enhancement
          <small
            >...before even thinking about
            <dfn data-tag="Web Components">web components</dfn></small
          >
        </li>
        <li>
          Extend HTML with Custom Elements
          <small
            >...and Think about attaching logic later on (to turn them into Web
            Components)</small
          >
        </li>
        <li>
          Prefer Light DOM
          <small
            >...before even thinking about
            <dfn data-tag="Shadow DOM">Shadow DOM</dfn></small
          >
        </li>
        <li>
          Understand Shadow DOM
          <small>...before building complex components</small>
        </li>
      </menu>
    `;
  }
}
