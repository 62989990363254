import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageMission extends RoutePage {
  static get properties() {
    return {
      handles: {
        type: Object,
      },
      tags: {
        type: Array,
      },
    };
  }

  renderContent() {
    return html`
      <section>

      <blockquote class="hero">
      At the forefront of a new Web Development Paradigm
    </blockquote>

        <div>
          <h2>The Situation</h2>
          <p>
            The web and app develop&shy;ment landscape has been heavily
            influenced by a <b>bandwagon effect</b>, leading to the dominance of
            React, Angular and Vue over the past decade. This has fostered a
            <a href="/leading-narrative">prevailing belief</a>

            that using a framework is essential for building any
            professional-grade application.
          </p>

          <blockquote>
            The <b>Pure Web Foundation</b> was created to
            <em>change the narrative</em>
            that you can't possibly create a professional site or app without a
            framework.
          </blockquote>

          <div data-persona-related="toggle">
            <div data-persona="dev">
              <p>
                Many developers have embraced this narrative, often overlooking
                alternative approaches that can be equally, if not more,
                effective.
              </p>

              <p>
                The allure of the major frameworks' popularity and community
                support can overshadow the potential benefits of exploring
                different technologies.
              </p>
            </div>

            <div data-persona="dec">
              <p>
                For decision makers and tech leaders (CTO/Head of Development),
                the pressure to conform to industry standards can be immense.
              </p>

              <p>
                The persistent narrative around frameworks can make it
                challenging to consider or advocate for alternative solutions,
                even when they might better align with specific project needs or
                organizational goals.
              </p>
            </div>
          </div>
        </div>
      </section>
    `;
  }

  renderChildren() {}
}
