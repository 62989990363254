import { RoutePage } from "../../shared/route-page";
import { html } from "lit";

export class PagePureWebApps extends RoutePage {
  renderContent() {
    return html`<p>
        When you're trying to build a complex app using The Standards, only The
        Standards and Nothing but The Standards, you'll discover that there are
        a couple of components that are essential in being able to quickly
        iterate in <em>actual app features</em>.
      </p>

      <p>
        Here's a list of <strong>base facilities</strong> you'd expect in frameworks but that you
        need to get an alternative for once you're going frameworkless.
      </p> `;
  }
}
