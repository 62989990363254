import { html } from "lit";
import { ComponentPage } from "./component-page";

const code = {
  html: `<my-comp></my-comp>`,
  js: `import {html,LitElement} from "https://cdn.jsdelivr.net/gh/lit/dist@3/core/lit-core.min.js";

customElements.define("my-comp", class MyComp extends LitElement {
  static properties = {
    counter: { type: Number }
  }

  connectedCallback() {
    super.connectedCallback();
    this.counter = 0;
    this._intervalId = setInterval(() => { this.counter++ }, 1000);
  }

  disconnectedCallback() {
    clearInterval(this._intervalId);
    super.disconnectedCallback();
  }

  render() {
    return html\`<h2>Test: \${this.counter}</h2>\`;
  }
});`,
};

export class PageTemplating extends ComponentPage {
  renderContent() {
    return html`
      <blockquote>Ever heard of ECMAScript String Literals?</blockquote>

      <call-out icon="code" title="String Literals">
        <code-example .code=${code}></code-example>
      </call-out>

      <coming-soon>
        More about templating in Web Standards environments to follow soon...
      </coming-soon>
    `;
  }
}
