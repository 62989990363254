import { css, html, LitElement } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageContact extends RoutePage {
  renderContent() {
    return html`
      <section class="block">
        <h3>
          <a href="mailto:info@neerventure.com">info@neerventure.com</a>
        </h3>
        <h3>
          <a href="tel:+31624615038">+31 6 246 150 38</a>
        </h3>
      </section>
    `;
  }
}
