import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { RoutePage } from "../shared/route-page";
import { FanDomain } from "../domains/fan-domain";
import { groupBy } from "../shared/common";
import { BlogDomain } from "../domains/blog-domain";

export class PageHome extends RoutePage {
  #blogDomain = new BlogDomain();

  static get properties() {
    return {
      handles: {
        type: Object,
      },
      tags: {
        type: Array,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    FanDomain.list().on("ready", (e) => {
      this.handles = e.detail;
    });
  }

  renderContent() {
    const me = this;

    if (!this.handles) return;

    return html`
      ${this.renderFans()} ${this.renderFeatured()}

      <section>
        <div>
          <persona-filter></persona-filter>

          <div data-persona-related="toggle">
            <p>
              In Web development, frameworks have been dominant over the last
              ten years.
            </p>

            <p>
              This has led to a common belief that frameworks are essential for
              building top-tier applications.
            </p>

            <div data-persona="dev">
              <p>
                Many developers have embraced this narrative, often overlooking
                alternative approaches that can be equally, if not more,
                effective.
              </p>

              <p>
                The allure of the major frameworks' popularity and community
                support can overshadow the potential benefits of exploring
                different technologies.
              </p>
            </div>

            <div data-persona="dec">
              <p>
                For decision makers and tech leaders (CTO/Head of Development),
                the pressure to conform to industry standards can be immense.
              </p>

              <p>
                The persistent narrative around frameworks can make it
                challenging to consider or advocate for alternative solutions,
                even when they might better align with specific project needs or
                organizational goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <nav id="entry-points" data-persona-related>
        <ul class="tiles">
          ${repeat(this.getEntryLevelPages(), (page) => {
            return html`
              <li data-persona="${page.config.persona}">
                <a href="${page.route}" title="${page.name}"
                  ><svg-icon icon="${page.config.icon}"></svg-icon>
                  <span>${me.getPageShortName(page)}</span>
                </a>
              </li>
            `;
          })}
        </ul>
      </nav>
    `;
  }

  renderFans() {
    const groups = groupBy(this.handles, "relation_id");

    return html`<section class="profile-list small wait-for-images">
      ${repeat(Object.values(groups), (group) => {
        return html`${repeat(
          group.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          }),
          (fan) => html` <fan-image handle=${fan.id}></fan-image> `
        )}`;
      })}

      <profile-image
        title="Add me as a fan!"
        class="interaction"
        @click=${(e) => {
          e.stopPropagation();
          e.preventDefault();
          app.openEmailClient("Add me as a fan!");
        }}
        src="/assets/img/plus.svg"
      ></profile-image>
    </section>`;
  }

  renderFeatured() {
    const blog = this.#blogDomain.getFeaturedBlog();

    return html`<a
      class="featured bg-img"
      style="--img: url(${blog?.img})"
      href="/blog${blog.id}"
    >
      <article>
        <header>
          <h2>${blog.title}</h2>
          <fan-image handle=${blog.handle_id}></fan-image>
        </header>
        <main>${blog.description}</main>
      </article>
    </a>`;
  }

  renderChildren() {}

  getEntryLevelPages() {
    return app.config.pages.filter((p) => {
      return p.path !== "/" && !p.parentRoute && !p.hidden && p.config?.icon;
    });
  }
}
