import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PageShowCaseQogni extends RoutePage {
  renderContent() {
    return html`
      <blockquote>
        Qogni is the #1 Platform to Upgrade your Brain and Body Health
      </blockquote>

      <figure>
        <img
          src="/assets/img/showcases/qogni/qogni-com.webp"
          alt="Qogni Homepage"
        />
        <figcaption>The Qogni.com Homepage</figcaption>
      </figure>
      <p>Qogni is a B2B/B2C Startup with a big ambition in The Netherlands.</p>

      <p>
        Being bootstrapped, they wanted to be as LEAN as possible in developing
        their first Minimum Loveable Product (MLP).
      </p>

      <p>
        An important choice Qogni made was to drop dual development stacks (Web
        and Mobile) and focus 100% on one single PWA.
      </p>

      <p>Some of the important choices Qogni made:</p>

      <ul class="simple-list">
        <li>
          No UI component library
          <small>
            Having a very distinctive, playful design, and few 'traditional'
            form entry screens, we decided to not go for
          </small>
        </li>
        <li>
          All the
          <a href="/pure-components/routing">
            important features of SPA Routing</a
          >
          using pure-web/spa.
          <small>This router has everything </small>
        </li>

        <li>
          Lots of Progressive Enhancement
          <small
            >pure-web/spa has built-in support for on-the-fly enhancing, even on
            content that is dynamically updated based on route changes and
            reactive rendering</small
          >
        </li>
        <li>
          Ultra-lightweight PWA (Progressive Web App)
          <small
            >A full-blown App at 180KB (compressed and minified) at this point.
            Compare that to big bulky Framework apps (5MB+), or the 50MB+ native
            app sizes. 
          </small>
        </li>

        <li>
          100% Web Components
          <small
            >Because of the memory- and DOM-efficiency. We use Google Lit, which
            makes reactive rendering a breeze.</small
          >
        </li>

        <li>
          Smart State Management
          <small
            >Using an implementation of a nested (native) Proxy class
            handler.</small
          >
        </li>

        <li>
          Efficient and easy auth.
          <small
            >One-time passwords and sign-in with LinkedIn and Facebook</small
          >
        </li>

        <li>
          Native App Experience
          <small
            >A lot of effort has been put into the native look and feel, using
            subtle micro-animations, fades and slides (yes, with 𝗩𝗶𝗲𝘄
            𝗧𝗿𝗮𝗻𝘀𝗶𝘁𝗶𝗼𝗻𝘀), gestures and push notifications, to name a few.</small
          >
        </li>
      </ul>

      <coming-soon> More about the Qogni Case to follow soon... </coming-soon>
    `;
  }
}
