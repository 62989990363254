import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageBenefits extends RoutePage {
  renderContent() {
    return html`<section>
      <p>
        Creating a full-blown, Professional Website or App (PWA) using
        <b>only the standards</b> is not only fully within reach, using only Web
        Standards.
      </p>
      <p>It's also a great way to:</p>
      <ol class="sumup large">
        <li>
          Boost Performance
          <small
            >Web Components are 100% native browser functionality and don't need
            to be transpiled into JavaScript in a build step.</small
          >
        </li>
        <li>
          Reduce Bundle Size
          <small
            >Think 10-50 times smaller! Frameworks and required (NPM) components
            add to the initial bundle size. In many cases, browser APIs and ES6+
            capabilities give access to the same functionality, but
            native.</small
          >
        </li>
        <li>
          Increase Readability
          <small
            >Semantic, real HTML constructs help, and leveraging Cascading Style
            Sheets provides great separation of concerns.</small
          >
        </li>
        <li>
          Increase Transferability
          <small
            >Developer/team handover is just easier when sticking to actual,
            stable browser standards.</small
          >
        </li>
        <li>
          Future-proof your Code
          <small
            >Web/Browser standards change in an extremely controlled, and
            backward compatible way.</small
          >
        </li>
        <li>
          Prevent Technical Debt
          <small
            >Frameworks can cause so-called
            <em>externally-inflicted technical debt</em> in your code in each
            iteration. Think about that.</small
          >
        </li>
        <li>
          Use Less Abstractions
          <small
            >A Web Component is just an extra (custom) HTML tag, and can be used
            as such, while abstracting the attached behavior in a JavaScript
            class.</small
          >
        </li>
      </ol>
    </section> `;
  }

}
