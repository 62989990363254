import { LitElement, css, html } from "lit";
import { repeat } from "lit/directives/repeat.js";

const PERSONAS = [
  {
    name: "Developer",
    tooltip: "Filter for developers",
    value: "dev",
    icon: "code",
  },
  {
    name: "Don't filter",
    tooltip: "Remove filter",
    value: "none",
    icon: "close",
  },
  {
    name: "Decision Maker",
    tooltip: "Filter for decision makers",
    value: "dec",
    icon: "legal",
  },
];

/**
 * Responsive slide-out panel Container Web Component
 * Supports coolean [open] attribute for opening and closing (using Web Standard best practices).
 * Used Shadow DOM and slotting for containment.
 */
customElements.define(
  "persona-filter",
  class DateInput extends LitElement {
    static formAssociated = true;

    static get styles() {
      return [
        css`
          :host {
            --def-rad: 0.4rem;
            display: block;
            border-radius: var(--def-rad, var(--def-rad));
          }

          header {
            text-align: center;
          }

          h2 {
            margin-bottom: 0.2rem;
            letter-spacing: 0.1rem;
            font-weight: 200;
            font-variant: small-caps;
            color: var(--color-h);
          }

          fieldset {
            border: 0;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: center;
          }

          #none {
            opacity: 0.6;
            filter: saturate(60%);
          }

          label {
            border-radius: 1rem;
            border: 1px solid #111;
            max-height: 120px;
            width: calc(100vw / 6);
            max-width: 120px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-items: center;
            align-items: center;
            justify-content: center;
            text-align: center;
            --icon-size: 64px;
            cursor: pointer;
          }

          label.active {
            border-color: var(--color-h)
          }

          input[type="radio"] {
            opacity: 0;
          }

          span {
            color: var(--color-h);
            font-size: small;
          }

          @media only screen and (max-width: 600px) {
            label{
              --icon-size: 32px;
            }
          }
        `,
      ];
    }

    constructor() {
      super();
      this._internals = this.attachInternals(); // Attach internals
    }

    connectedCallback() {
      super.connectedCallback();
      this.value = localStorage.getItem("persona") ?? "none";
    }

    static get properties() {
      return {
        value: {
          type: String,
          attribute: true,
          reflect: true,
        },
      };
    }

    render() {
      return html`
        <header>
          <h2>Filter based on Role</h2>
          <small
            >You can select your role, in order to filter out content that might
            be irrelevant for you...</small
          >
        </header>

        <fieldset @click=${this.personaClick}>
          ${repeat(PERSONAS, (item) => {
            const selected = item.value === this.value;
            return html`
              <label
                id="${item.value}"
                title="${item.tooltip}"
                class="${selected ? "active" : ""}"
              >
                <input
                  ?checked=${selected}
                  type="radio"
                  name="persona"
                  value="${item.value}"
                />
                <svg-icon icon="${item.icon}"></svg-icon>
                <span data-label>${item.name}</span>
              </label>
            `;
          })}
        </fieldset>
      `;
    }

    personaClick(e) {
      const persona = e.target.closest("label")?.querySelector("input")?.value;

      if (persona) {
        this.value = persona;
        localStorage.setItem("persona", persona);
        document.documentElement.setAttribute("data-persona", persona);
        this.dispatchEvent(
          new Event("change", {
            detail: persona,
          })
        );
      }
    }
  }
);
