import { config } from "./pure-app.config";
import { html } from "lit";
import { PureSPA } from "pure-web/spa";
import { AutoComplete } from "pure-web/ac";
import { enhanceNavDropdownButton } from "pure-web/common";
import { CMS } from "./shared/cms";
import { getUniqueName } from "pure-web/common";

import {
  debounce,
  startsWithWord,
  debug,
  truncateString,
  enhanceAccordion,
  enhanceInputWithLabel,
  getContentFromLitHtml,
  enhanceTimeElement,
  showcaseElement,
  matchWordStart,
  parseHTML,
  enhanceWaitForImages,
} from "./shared/common";
import { polyfillsLoaded } from "./polyfills/polyfillsLoader";
import { VisibilityObserver } from "./shared/visibility-observer";
import { Glossarizer } from "./shared/glossarizer";
import { ref, createRef } from "lit/directives/ref.js";

const root = document.documentElement;

let lastScrollTop;

function isMobileView() {
  return window.innerWidth <= 600;
}

/**
 * Wraps content in a Modal dialog container - using native <dialog></dialog>
 */
class PureApp extends PureSPA {
  #cms;
  #ac;
  #metaObserver;
  #glossarizer;
  #omniOptions;
  #omniboxRef = createRef();

  constructor() {
    super();

    this.setupProgressiveEnhancement();
  }

  setupProgressiveEnhancement() {
    this.enhancers.add(".accordion", enhanceAccordion);
    this.enhancers.add("[data-label]", enhanceInputWithLabel);
    this.enhancers.add("nav[data-dropdown]", enhanceNavDropdownButton);
    this.enhancers.add("time[datetime]", enhanceTimeElement);
    this.enhancers.add(".wait-for-images", enhanceWaitForImages);
  }

  setupMetaObserver() {
    this.#metaObserver = new VisibilityObserver(
      this.querySelector("main"),
      "[data-tag]",
      (target) => {
        return Glossarizer.readDfn(target);
      }
    );

    this.#metaObserver
      .on("update", (e) => {
        if (Array.isArray(e.detail)) {
          this.currentMetaElements = e.detail;
        }
      })
      .observe();
  }

  getMetaHtml(selectedTerm) {
    const getRelatedContent = (tag) => {
      const results = this.cms.findByTags(tag);
      if (results.length) {
        return /*html*/ `<ul>
            ${results.map((item) => {
              return /*html*/ `
              <li>
                <a href="${item.url}" rel="noopener" target="_blank"
                  >${item.title}<small>${item.description}</small></a
                >
              </li>`;
            })}
          </ul>`;
      }
      return "";
    };

    const renderRelatedTag = (tag) => {
      return /*html*/ `<span>${tag}</span>`;
    };

    const renderRelatedTags = (term) => {
      if (!Array.isArray(term?.tags)) return "";

      return /*html*/ `<div class="badges">
          ${term.tags
            .map((tag) => {
              return renderRelatedTag(tag);
            })
            .join("")}
        </div>`;
    };

    const getTitle = (url) => {
      const route = app.config.routes[url];
      return route?.name ?? url;
    };

    const getHost = (url) => {
      try {
        return "on " + new URL(url).host;
      } catch {
        /**/
      }
    };
    const renderLink = (item) => {
      if (!item?.url) return "";

      if (item.url.startsWith("/"))
        return /*html*/ `<a
        title="Go to ${getTitle(item.url)}"
        href="${item.url}"
        ><svg-icon icon="link" color="white"></svg-icon
      ></a>`;

      return /*html*/ `<a
          title="Open external link ${getHost(item.url)}"
          href="${item.url}"
          rel="noopener"
          target="_blank"
          ><svg-icon icon="link" color="white"></svg-icon
        ></a>`;
    };

    const term = this.cms.data.glossary[selectedTerm];

    if (!term && selectedTerm.indexOf(" element")) {
      const term = app.cms.data.htmlIndex[selectedTerm];
      return /*html*/ `
      <h4>${selectedTerm} ${renderLink(term)}</h4>
      <small>${term?.description}</small>
    `;
    }
    return /*html*/ `
      <h4>${selectedTerm} ${renderLink(term)}</h4>
      <small>${term?.description}</small>
      <div class="related">${getRelatedContent(selectedTerm)}</div>
      ${renderRelatedTags(term)}
    `;
  }

  get metaPanel() {
    return this.querySelector("#meta-panel");
  }

  addDfnHook() {
    document.addEventListener("click", (e) => {
      const tagToSelect = this.#metaObserver.generateKey(
        e.target.closest("[data-tag]")
      );

      if (tagToSelect) this.showGlossaryTerm(tagToSelect);
    });
  }

  showGlossaryTerm(term) {
    this.metaPanel.show(this.getMetaHtml(term));
  }

  // get routes from page config.
  static get config() {
    return config;
  }

  async initApp() {
    await polyfillsLoaded;

    this.#cms = await CMS.open();

    const main = this.querySelector("main");

    this.#glossarizer = new Glossarizer(main, this.cms.data.glossary).run();

    return true;
  }

  get cms() {
    return this.#cms;
  }

  get glossarizer() {
    return this.#glossarizer;
  }

  async beforeInitialize() {
    return await this.initApp();
  }

  async beforeRouting() {}

  render() {
    return html`
      <header>
        <a href="/" class="site-header" @click=${this.onSiteHeaderClick}>
          <h1>PURE Web Foundation</h1>
        </a>
        ${this.renderOmnibox()}
      </header>
      <main>
        ${super.render()}
        <nav>${this.renderRelated()}</nav>
      </main>
      <slide-panel id="meta-panel"></slide-panel>
      <footer class="proclaimer">${this.renderFooter()}</footer>
    `;
  }

  connectedCallback() {
    super.connectedCallback();

    this.hookScrollDirection();
  }

  onSiteHeaderClick(e) {
    if (location.pathname === "/") {
      e.preventDefault();
    }
  }

  hookScrollDirection() {
    const scrollElement = window;
    scrollElement.addEventListener(
      "scroll",
      debounce(() => {
        if (window.scrollY === 0) root.removeAttribute("data-scroll");
        else
          root.setAttribute(
            "data-scroll",
            window.scrollY > lastScrollTop ? "down" : "up"
          );

        lastScrollTop = window.scrollY;
      })
    );
  }

  get notFoundPage() {
    return html`<div class="hero error-page flex">
      <img alt="Compact Pure Logo" src="/assets/img/pure-p.svg" width="150px" />
      <h2>age not found</h2>
    </div>`;
  }

  renderFooter() {
    return html`<small>
      Developed using
      <a rel="noopener" href="/manifesto">PURE Manifesto</a>
      by
      <a rel="noopener" href="https://neerventure.com" target="_blank"
        >Neerventure</a
      >
      | Partners: ${this.renderPartners()} |

      <a href="mailto:${config.org.email}?subject=PWF%20Information">Contact</a>
    </small>`;
  }

  renderPartners() {
    return html`
      <a rel="noopener" href="http://www.qogni.com" target="_blank">Qogni</a>
      ,
      <a rel="noopener" href="https://www.kasorb.com/" target="_blank"
        >Kasorb</a
      >
    `;
  }

  openEmailClient(subject = "Information") {
    window.open(
      `mailto:${config.org.email}?subject=${encodeURIComponent(subject)}`
    );
  }

  renderRelated() {
    const cls = this.activeRoute?.options?.definedByClass;

    if (cls) {
      const pageInstance = new cls();
      if (typeof pageInstance.renderRelated === "function")
        return pageInstance.renderRelated();
    }
  }

  renderOmnibox() {
    return html`<label
      @click=${this.omniboxLabelClick}
      id="omnibox"
      ${ref(this.#omniboxRef)}
    >
      <input
        @focus=${this.setupOmnibox}
        @blur=${this.omniboxBlur}
        placeholder="Find everything..."
        type="search"
      />
      <svg-icon icon="menu"></svg-icon>
    </label>`;
  }

  setupOmnibox(e) {
    this.#ac = AutoComplete.connect(e, this.globalOmniboxOptions);
  }

  omniboxBlur() {
    if (!isMobileView()) {
      if (this.#ac.resultsDiv) {
        this.#ac.resultsDiv.classList.remove("ac-active");
        this.#ac.resultsDiv.innerHTML = "";
      }
    }
  }

  omniboxLabelClick(e) {
    if (["LABEL", "SVG-ICON"].includes(e.target.nodeName)) {
      const suggestions = this.omniboxLabel.querySelector(".ac-suggestion");
      if (suggestions?.matches(".ac-active")) {
        suggestions.classList.remove("ac-active");
        root.removeAttribute("data-omnibox-shown");
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  get omniboxLabel() {
    return this.#omniboxRef.value;
  }

  setupOmniboxShortcut() {
    document.addEventListener("keypress", (e) => {
      const isOmniboxFocused = this.omniboxLabel.contains(
        document.activeElement
      );

      if (e.key === "/") {
        if (!isOmniboxFocused) {
          e.preventDefault();
          this.omniboxLabel.focus();
        }
      }
    });
  }

  omniboxController(ac) {
    return {
      show: () => {
        ac.resultsDiv.classList.toggle("ac-active", true);
        root.setAttribute("data-omnibox-shown", "");
      },
      hide: (reason) => {
        if (reason === "clear") return;

        if (ac.resultsDiv.matches(".ac-active")) {
          ac.resultsDiv.classList.toggle("ac-active", false);
          root.removeAttribute("data-omnibox-shown");
          ac.resultsDiv.innerHTML = "";
          setTimeout(() => {
            root.querySelector(".ac-input").blur();
          }, 100);
        }
      },
      empty: () => {},
      clear() {
        ac.clear();
      },
    };
  }

  get globalOmniboxOptions() {
    const tryGetPageContent = (type) => {
      try {
        const instance = new type();
        const method = instance.renderContent ?? instance.render;
        let content = getContentFromLitHtml(method)?.bind(instance);
        try {
          content = new DOMParser()
            .parseFromString(content, "text/html")
            .body.textContent.trim();
        } catch {
          /**/
        }

        return content;
      } catch {
        /**/
      }
    };

    if (!this.#omniOptions) {
      let localCategories = {};

      for (const route of Object.values(this.config.routes)) {
        if (route.run) {
          const pageInstance = new route.run();
          if (typeof pageInstance.contextSearch === "function") {
            const arr = pageInstance.contextSearch();

            arr.forEach((o) => {
              localCategories = {
                ...localCategories,
                ...o.categories,
              };
            });
          }
        }
      }

      this.#omniOptions = {
        //debug: true,
        controller: this.omniboxController,
        categories: {
          Navigation: {
            sortIndex: 1,
            trigger: () => {
              return true;
            },
            action: (options) => {
              location.href = options.path;
            },
            getItems: (options) => {
              let findInText = -1;

              const results = this.config.pages.filter((i) => {
                if (i.hidden) return false;
                const findInPath =
                  (options.search === "" && i.path === "/") ||
                  (options.search.length &&
                    i.path.startsWith(options.search.toLowerCase()));

                if (findInPath) {
                  i.matchContext = i.path;
                  i.icon = "link";
                  return true;
                }
                if (options.search.length) {
                  if (i.name) {
                    findInText = startsWithWord(i.name, options.search);
                    if (findInText !== -1) {
                      i.matchContext = truncateString(
                        i.name.substring(findInText),
                        80
                      );
                      return true;
                    }
                    if (i.config?.tags) {
                      const matchingTags = i.config.tags.filter((tag) => {
                        if (startsWithWord(` ${tag} `, options.search) !== -1) {
                          i.matchContext = `Tag: ${tag}`;
                          i.icon = "tag";
                          return true;
                        }
                      });
                      return matchingTags?.length > 0;
                    }
                  }
                }
              });
              if (debug) console.log(results);
              return results.map((i) => {
                return {
                  text: `${truncateString(i.name, 80)}`,
                  description: i.config?.description ?? i.matchContext,
                  path: i.path,
                  icon: i.icon,
                };
              });
            },
          },
          Pages: {
            sortIndex: 2,
            trigger: (options) => {
              return options.search.length >= 2;
            },
            action: (options) => {
              const id = options.config?.id;
              if (id)
                location.href = `${options.path}?key=${options.config?.id}`;
              else location.href = options.path;
            },
            getItems: (options) => {
              //let findInText = -1;
              const results = this.config.pages.filter((i) => {
                const content = tryGetPageContent(i.run);

                i.text = `${i.name} ${i.config?.description ?? ""} ${
                  content ?? i.config?.body ?? ""
                }`;

                const found = matchWordStart(i.text, options.search);
                if (found) {
                  i.matchContext = i.description;
                  i.icon = "text";
                  return true;
                }

                if (i.config?.tags) {
                  const matchingTags = i.config.tags.filter((tag) => {
                    if (startsWithWord(` ${tag} `, options.search) !== -1) {
                      i.matchContext = `Tag: ${tag}`;
                      i.icon = "tag";
                      return true;
                    }
                  });

                  return matchingTags?.length > 0;
                }
              });

              return results.map((i) => {
                return {
                  text: `${truncateString(i.text, 80)}`,
                  description: i.matchContext,
                  path: i.path,
                  id: i.id,
                  icon: "link",
                };
              });
            },
          },
          Content: {
            trigger: (options) => {
              return options.search.length > 1;
            },
            action: (options) => {
              const mapper = {
                blog: () => {
                  app.goTo(`/blog${options.id}`);
                },
                glossary: () => {
                  app.showGlossaryTerm(options.key);
                },
                routes: () => {
                  app.goTo(options.path);
                },
                source: () => {
                  const hnd = window.open("about:blank");
                  hnd.location = options.url ?? options.permaLink;
                },
                example: () => {
                  this.showCodeExample(options);
                },
                handle: () => {
                  app.goTo(`/profile/${options.id}`);
                },
                htmlIndex: () => {
                  app.goTo(`/manifesto/html`);
                  setTimeout(() => {
                    const elm = document.querySelector(
                      `[data-tag="${options.text} element"]`
                    );
                    if (elm) showcaseElement(elm);
                  }, 500);
                },
                htmlMeta: () => {
                  app.goTo(`/manifesto/html`);
                },
              };

              const mapped = mapper[options.source];
              if (mapped) mapped();
              else {
                console.warn("Category not mapped", options);
              }
            },

            getItems: (options) => {
              return app.cms
                .find(options.search)

                .map((item) => {
                  return {
                    description:
                      item.description ??
                      item.slogan ??
                      item.title ??
                      truncateString(item.content ?? "", 80),
                    ...item,
                  };
                });
            },
          },

          //...localCategories,
        },
      };
    }

    return this.#omniOptions;
  }

  showCodeExample(item) {
    const id = getUniqueName("ex");
    const example = parseHTML(
      /*html*/ `<call-out icon="code" title="${item.title}"><code-example></code-example></call-out>`
    )[0];
    app.metaPanel.show(`<div id="${id}"></div>`);
    setTimeout(() => {
      document.getElementById(id).appendChild(example);
      example.querySelector("code-example").code = item.code;
    }, 200);
  }

  firstUpdated() {
    super.firstUpdated();

    this.setupMetaObserver();

    this.addDfnHook();

    this.setGlobalFlags();

    this.setupOmniboxShortcut();
  }

  setGlobalFlags() {
    document.documentElement.setAttribute(
      "data-persona",
      localStorage.getItem("persona") ?? "all"
    );
  }

  getUrlMetaHtml(metaTags) {
    const title = metaTags["og:title"] ?? metaTags.title;

    return /*html*/ `
      <h3 title="${title}">${title}</h3>
      <article>
        <header style="--bg-color: ${metaTags["theme-color"]}">
          <img alt="" src="${metaTags["og:image"] ?? metaTags.icon}"/>
        </header>
        <section class="details">
          ${
            metaTags.description ??
            `No further info available on ${metaTags.host}`
          }
        </section>
        <footer>
        <a title="Go to ${metaTags.host}" href="${
      metaTags.url
    }" rel="noopener" target="_blank">
          <svg-icon icon="link" color="var(--color-h)"></svg-icon>
          <small>
            ${metaTags.host}
          </small>
          </a>
        </footer>
      </article>
    `;
  }

  updated() {
    super.updated();

    setTimeout(() => {
      if (location.pathname !== this.lastPath) this.routeSwitched();

      this.lastPath = location.pathname;
    }, 500);
  }

  routeSwitched() {
    if (!this.metaPanel.isOpening) this.metaPanel.open = false;
  }
}
customElements.define("pure-app", PureApp);
