import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PagePureManifestoUSD extends RoutePage {
  renderTitle(){
    return html`${super.renderTitle()}: 4. Understand Shadow DOM`
  }


  renderContent() {
    return html`
      <blockquote>
        Understand Shadow DOM
        <small>...before building complex components</small>
      </blockquote>

      <p>
        Using Shadow DOM in Web Components offers significant advantages but
        also introduces additional complexity compared to using Light DOM.
        Here's a breakdown:
      </p>

      <h3>Power of Shadow DOM</h3>

      <ul class="accordion">
        <li>
          <details>
            <summary>🎁 Encapsulation</summary>
            <div>
              <p>
                <strong>Isolation</strong>: Shadow DOM creates a separate,
                encapsulated DOM tree for your component. This means styles and
                scripts inside the Shadow DOM do not affect the rest of the
                document, and vice versa.
              </p>
              <p>
                <strong>Scoped Styles</strong>: Styles defined within the Shadow
                DOM are scoped to that component, preventing style conflicts and
                ensuring that your component looks the same regardless of where
                it's used.
              </p>
            </div>
          </details>
        </li>
        <li>
          <details>
            <summary>⭕ Reusability</summary>
            <p>
              <strong>Self-contained Components</strong>: Because Shadow DOM
              encapsulates everything, you can create highly reusable components
              that don't rely on the surrounding environment.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>🔒 Security</summary>
            <p>
              <strong>Preventing CSS Leaks</strong>: Encapsulation helps prevent
              accidental or malicious style overrides from affecting your
              component.
            </p>
          </details>
        </li>
      </ul>

      <h3>Shadow DOM Complexity</h3>
      <ul class="accordion">
        <li>
          <details>
            <summary>📈 Learning Curve</summary>
            <p>
              <strong>New Concepts</strong>: Developers need to understand new
              concepts like shadow roots, slots, and the Shadow DOM API.
            </p>
            <p>
              <strong>Debugging</strong>: Debugging can be more challenging
              because the encapsulated nature of Shadow DOM means you can't
              easily inspect or manipulate it from the outside.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>🚀 Performance Considerations</summary>
            <p>
              <strong>Rendering</strong>: While Shadow DOM can improve
              performance by reducing style recalculations, it can also
              introduce overhead due to the additional DOM tree management.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>🫱🏼‍🫲🏼 Interoperability</summary>
            <div>
              <p>
                <strong>Event Handling</strong>: Events in the Shadow DOM are
                retargeted, which can complicate event handling and require
                additional code to manage event propagation.
              </p>
              <p>
                <strong>Accessibility</strong>: Ensuring accessibility can be
                more complex as you need to manage focus and ARIA attributes
                within the encapsulated DOM.
              </p>
            </div>
          </details>
        </li>
      </ul>

      <h3>Light DOM Simplicity</h3>
      <ul class="accordion">
        <li>
          <details>
            <summary>🌍 Global Access</summary>

            <p>
              <strong>Ease of Use</strong>: Elements in the Light DOM are part
              of the main document tree, making them easier to access and
              manipulate with standard DOM methods.
            </p>
            <p>
              <strong>Simpler Debugging</strong>: Since Light DOM elements are
              not encapsulated, they are easier to inspect and debug using
              standard browser tools.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>🟡 Less Overhead</summary>
            <p>
              <strong>Performance</strong>: Light DOM does not introduce the
              additional overhead of managing a separate DOM tree, which can be
              beneficial for simpler components
            </p>
          </details>
        </li>
      </ul>
    `;
  }
}
