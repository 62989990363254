import "./date-time";

export function enhanceGlossayDefinition(dfn) {
  const term = window.data.glossary[dfn.textContent];
  if (term) {
    dfn.title = term.description ?? "";
    if (term.url)
      dfn.addEventListener("mousemove", (e) => {
        const selected = e.target.closest("dfn.active");
        if (selected) {
          const rect = selected.getBoundingClientRect();
          selected.classList.toggle(
            "icon-hover",
            e.clientX - rect.left > 240 && e.clientY - rect.top < 35
          );
        }
      });
    dfn.setAttribute("data-url", term.url);
    dfn.addEventListener("click", (e) => {
      const selected = e.target.closest("dfn");

      if (selected.classList.contains("icon-hover")) {
        const handle = window.open("about:blank");
        handle.location = term.url;
      } else if (selected.classList.contains("active")) {
        selected.classList.remove("active");
        return;
      }

      document.querySelectorAll("dfn").forEach((i) => {
        i.classList.toggle("active", i === selected);
        if (i.classList.contains("active"))
          autoEscape(i, () => {
            i.classList.remove("active");
          });
      });
    });
  }
}

export function enhanceAccordion(accordion) {
  accordion.addEventListener("click", (e) => {
    accordion.querySelectorAll("details").forEach((group) => {
      if (group.open && group !== e.target.closest("details")) {
        e.stopPropagation();
        group.open = false;
      }
    });
  });
}

/**
 * Calls the given callback when the ESC key is pressed, and when
 * the user clicks outside the given element.
 * @param {HTMLElement} element
 * @param {Function} callback
 */
export function autoEscape(element, callback) {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") callback();
  });
  document.addEventListener("mousedown", (e) => {
    if (!element.contains(e.target)) callback();
  });
}

/**
 * Throttles execution of any function
 * @param {Function} fn - Function to fire
 * @param {Number} timeoutMs - time in milliseconds to buffer all calls to fn.
 */
export function throttle(fn, timeoutMs = 100) {
  let handle;
  return function executedFunction(...args) {
    const fire = () => {
      clearTimeout(handle);
      fn(...args);
    };
    clearTimeout(handle);
    handle = setTimeout(fire, timeoutMs);
  };
}

/**
 * Debounces events that occur repeatedly, such as resize and mousemove events.
 * @param {Function} fn
 */
export function debounce(fn) {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
}

/**
 * Tests whether any word in text starts with searchString (case insensitive)
 * @param {String} text
 * @param {String} searchString
 * @returns {Number} position in string, or -1 if not found
 */
export function startsWithWord(text, searchString) {
  const rx = new RegExp("\\b" + searchString, "i");
  const match = text.match(rx);
  return match?.index || -1;
}

/**
 * Truncate string at given length-3 and add '...' when truncated
 * @param {String} str
 * @param {Number} maxLength
 * @returns {String}
 */
export function truncateString(str, maxLength) {
  return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str;
}

export function debug() {
  return ["localhost", "127.0.0.1"].includes(location.hostname);
}

/**
 * Determines whether the request comes from a mobile device using user-agent.
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Deep-proxifies an object to pass control over access to the given handler
 * @param {Object} object to proxify
 * @param {Object} proxyHandler - as in Proxy class
 * @returns {Object} proxied object
 */
export function deepProxy(obj, proxyHandler = {}) {
  // local function for deep proxying (recursive)
  const proxify = (obj, path = "#") => {
    for (const key of Object.keys(obj)) {
      const type = typeof obj[key];
      if (type === "object" || Array.isArray(obj[key])) {
        const subPath = `${path}/${key}`;
        if (obj[key] != null) obj[key] = proxify(obj[key], subPath);

        const p = path.lastIndexOf("/template");
        if (p !== -1) {
          const localPath = path.substring(0, p);
          obj[key]._parentPath = localPath;
        }
      }
    }

    const internalHandler = {};

    if (proxyHandler.get)
      internalHandler.get = (target, property) => {
        return proxyHandler.get(target, property, path);
      };

    if (proxyHandler.set)
      internalHandler.set = (target, property, value) => {
        return proxyHandler.set(target, property, value, path);
      };

    return new Proxy(obj, internalHandler);
  };

  return proxify(obj);
}

/**
 * Input Template.
 * @type {string}
 */
const inputTemplate = /*html*/ `
<label>
  <span data-label></span>
  <span class="placeholder"></span>
</label>`;

/**
 * Enhance inputs having data-label attribute.
 *
 * @param {HTMLElement|Document|null} root On which root element we should apply it.
 */
export function enhanceInputWithLabel(input) {
  const labelText = input.getAttribute("data-label") ?? "";
  if (labelText.length) {
    const label = parseHTML(inputTemplate)[0];
    const type = input.getAttribute("type") || "text";
    input.insertAdjacentElement("beforebegin", label);
    label.querySelector(".placeholder").replaceWith(input);
    label.querySelector("span[data-label]").textContent = labelText;
    input.removeAttribute("data-label");
    input.setAttribute("type", type);
  }

  const icon = input.getAttribute("data-icon") || "";
  if (icon) {
    const iconColor = input.getAttribute("data-icon-color") || "";
    const iconSize = input.getAttribute("data-icon-size") || "";
    const iconHtml = /*html*/ `<svg-icon icon="${icon}" color="${iconColor}" size="${iconSize}"></svg-icon>`;
    input.insertAdjacentElement("afterend", parseHTML(iconHtml)[0]);
  }
}

/**
 * Uses MutationObserver to wait for DOM changes
 */
export function listenForDOMChanges(
  targetNode,
  config = {
    childList: true,
    subtree: true, // Observe changes in the descendants of the target node
  }
) {
  return new Promise((resolve) => {
    let observer;
    // Create an observer instance linked to the callback function
    observer = new MutationObserver(() => {
      observer.disconnect();
      resolve();
    });
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  });
}

export function getContentFromLitHtml(method) {
  const result = method();
  return getHTML(result);
}

/**
 * Enhances <time> elements to use smart formatting and update themselves
 * regularly because they show smart 'humanized' date-times
 * such as '10 seconds ago'
 * @param {HTMLElement} timeElement
 */
export function enhanceTimeElement(timeElement) {
  const date = new Date(timeElement.getAttribute("datetime"));
  timeElement.setAttribute("title", date.toLocaleDateString());

  let fixedInterval = Number(
    timeElement.getAttribute("data-update-interval") ?? 0
  );

  const update = () => {
    timeElement.textContent = date.format({ mode: "relative", rangeDays: 365 });
  };

  let smartInterval = fixedInterval;

  const check = () => {
    update();
    if (!fixedInterval) {
      const seconds = Math.floor((new Date() - date) / 1000);
      smartInterval =
        seconds < 60
          ? 1000
          : seconds < 300
          ? 10000
          : seconds < 3000
          ? 30000
          : 60000;
    }
    setTimeout(check, smartInterval);
  };
  check();
}

export function enhanceWaitForImages(containerElement) {
  waitForImages(containerElement).then(() => {
    const delayStep = 0.05;
    containerElement.querySelectorAll(":scope > *").forEach((elm, index) => {
      elm.style.transitionDelay = `${delayStep * index}s`;
    });

    setTimeout(() => {
      containerElement.classList.add("images-loaded");
    }, 1);
  });
}

/**
 * Returns a Promise that resolves when all images in the given container have downloaded.
 */
export function waitForImages(container) {
  const imgElements = container.querySelectorAll("img");

  const imgPromises = Array.from(imgElements).map((img) => {
    return new Promise((resolve, reject) => {
      const imgSrc = img.src;
      const image = new Image();
      image.onload = () => resolve(imgSrc);
      image.onerror = (error) => reject(error);
      image.src = imgSrc;
    });
  });
  return Promise.all(imgPromises);
}

const defaulShowCaseOptions = {
  className: "showcased",
  highlightAfterMs: 200,
  highlightTimeoutMs: 2000,
};

/**
 * Showcase the element (make visible, scroll to and highlight temporarily)
 */
export function showcaseElement(element, options = defaulShowCaseOptions) {
  if (!element) return;

  options = {
    ...defaulShowCaseOptions,
    ...(options ?? {}),
  };

  let node = element;
  while (node) {
    node = node.parentNode;
    if (node?.nodeName === "DETAILS") node.open = true;
  }

  element.scrollIntoView({
    behavior: "smooth",
  });

  setTimeout(() => {
    element.classList.add(options.className);
    setTimeout(() => {
      element.classList.remove(options.className);
    }, options.highlightTimeoutMs);
  }, options.highlightAfterMs);
}

/**
 * Matches the beginning of words in a sentence (case-insensitive)
 * @param {String} text text to search
 * @param {String} prefix of words to find
 * @returns { Boolean } true if one or more words in the text start with the prefix
 */
export function matchWordStart(text, prefix) {
  const regex = new RegExp(`\\b(${prefix})\\w*`, "gi");
  return regex.test(text);
}

export function kebabToWords(str) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function ensureRoute(path) {
  return new Promise((resolve) => {
    let wait = 1;
    if (location.pathname !== new URL(path, location.origin).pathname) {
      app.goTo(path);
      wait = 1000;
    }

    setTimeout(() => {
      const id = "#" + (path.split("#")[1] ?? "");
      if (id.length > 1) {
        const element = document.querySelector(id);
        if (element) showcaseElement(element);
        resolve(element);
      } else resolve();
    }, wait);
  });
}

export function getHTML(stringOrLitResult) {
  let html = stringOrLitResult;
  if (typeof html === "object") html = html.values.join("");
  if (html.indexOf("<") === -1 && Array.isArray(stringOrLitResult.strings))
    html = stringOrLitResult.strings.join("");

  return html.trim();
}

export function sanitizeHtml(content, { allowedTags = [] }) {
  const element = document.createElement("div");
  element.innerHTML = content;

  // Define the list of allowed tags
  const allowedTagsSet = new Set(allowedTags);

  // Remove span elements but preserve their innerHTML
  Array.from(element.querySelectorAll("span")).forEach((span) => {
    const parent = span.parentNode;
    parent.insertBefore(span.cloneNode(true), span);
    span.remove();
  });

  // Replace &nbsp; with whitespace
  element.innerHTML = element.innerHTML.replace(/&nbsp;/g, " ");

  // Convert div tags to p tags
  Array.from(element.querySelectorAll("div")).forEach((div) => {
    const p = document.createElement("p");
    p.innerHTML = div.innerHTML;
    div.replaceWith(p);
  });

  // Remove script tags
  Array.from(element.querySelectorAll("script")).forEach((script) =>
    script.remove()
  );

  // Remove style tags
  Array.from(element.querySelectorAll("style")).forEach((style) =>
    style.remove()
  );

  // Remove iframe tags
  Array.from(element.querySelectorAll("iframe")).forEach((iframe) =>
    iframe.remove()
  );

  // Process inline styles and strip out potentially harmful attributes
  Array.from(element.querySelectorAll("*")).forEach((node) => {
    if (!allowedTagsSet.has(node.tagName.toLowerCase())) {
      const parent = node.parentNode;
      while (node.firstChild) {
        parent.insertBefore(node.firstChild, node);
      }
      parent.removeChild(node);
    }
    // Remove all event handlers
    [
      "onload",
      "onclick",
      "onerror",
      "onmouseover",
      "onmouseout",
      "onmousedown",
      "onmouseup",
    ].forEach((attr) => node.removeAttribute(attr));

    // Process inline styles
    if (node.hasAttribute("style")) {
      let styleValue = node.getAttribute("style").trim();

      // Only allow color property
      styleValue = styleValue.replace(
        /[^;]*color\s*:\s*([^;]+);?/g,
        "color:$1;"
      );

      // Remove empty style attribute if nothing left after filtering
      if (!styleValue.trim()) {
        node.removeAttribute("style");
      } else {
        node.setAttribute("style", styleValue);
      }
    }

    // Remove href attributes that start with javascript:
    if (node.hasAttribute("href")) {
      const href = node.getAttribute("href");
      if (href.toLowerCase().startsWith("javascript:")) {
        node.removeAttribute("href");
      }
    }

    // Remove src attributes that start with javascript:
    if (node.hasAttribute("src")) {
      const src = node.getAttribute("src");
      if (src.toLowerCase().startsWith("javascript:")) {
        node.removeAttribute("src");
      }
    }
  });

  return element.innerHTML;
}

export function groupBy(arr, key) {
  return arr.reduce((acc, curr) => {
    const groupKey = key instanceof Function ? key(curr) : curr[key];
    acc[groupKey] = acc[groupKey] || [];
    acc[groupKey].push(curr);
    return acc;
  }, {});
}

/**
 * Queues a call for when the main thread is free.
 * @param {Function} fn - the function to call
 */
export function enQueue(fn) {
  setTimeout(fn, 0);
}

/**
 * Returns true if the given string is a valid URL.
 * @param {String} str
 * @returns { Boolean }
 */
export function isUrl(str) {
  try {
    if (typeof str !== "string") return false;
    if (str.indexOf("\n") !== -1 || str.indexOf(" ") !== -1) return false;
    if (str.startsWith("#/")) return false;
    const newUrl = new URL(str, window.location.origin);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch {
    return false;
  }
}

/**
 * Generates an HTML NodeList by parsing the given HTML string
 * @param {String} html
 * @returns {NodeListOf<ChildNode>} DOM element
 */
export function parseHTML(html) {
  return new DOMParser().parseFromString(html, "text/html").body.childNodes;
}

/**
 * Opens a window and returns the handle.
 * @param {String} url
 * @param {Number} width
 * @param {Number} height
 * @returns window handle reference
 */
export function openCenteredWindow(url, width, height) {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  return window.open(
    url,
    "",
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`
  );
}

export async function getSvgAsBase64(spriteUrl, symbolId) {
  try {
    // Fetch the SVG sprite sheet
    const response = await fetch(spriteUrl);
    const spriteText = await response.text();

    // Parse the SVG sprite sheet
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(spriteText, "image/svg+xml");

    // Extract the desired symbol
    const symbol = svgDoc.getElementById(symbolId);
    if (!symbol) {
      throw new Error(`Symbol with ID ${symbolId} not found`);
    }

    // Create a standalone SVG element
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute(
      "viewBox",
      symbol.getAttribute("viewBox") || "0 0 100 100"
    );
    svg.innerHTML = symbol.innerHTML;

    // Serialize the SVG and convert to base64
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const base64Svg = "data:image/svg+xml;base64," + btoa(svgString);

    return base64Svg;
  } catch (error) {
    console.error("Error fetching or processing SVG:", error);
  }
}

/**
 * Update the given Open Graph meta tags
 * @param {Object} tags
 */
export function updateMetaTags(tags = {}, nameSpace = "og") {
  const prefix = nameSpace ? `${nameSpace}:` : "";
  for (const [property, content] of Object.entries(tags)) {
    const name = `${prefix}${property}`;
    const metaTag = document.querySelector(`meta[property="${name}"]`);
    if (metaTag) {
      metaTag.setAttribute("content", content);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("property", name);
      newMetaTag.setAttribute("content", content);
      document.head.appendChild(newMetaTag);
    }
  }
}

/**
 * Load a script.
 * @param {string} url - URL or path to the script. Relative paths are
 * relative to the HTML file that initiated script loading.
 * @param {function} successCallback - Optional parameterless function that
 * will be called when the script has loaded.
 * @param {function} errorCallback - Optional function that will be called
 * if loading the script fails, takes an error object as parameter.
 * @public
 */
export function loadScript(url, successCallback, errorCallback) {
  let mLoadedScripts = {},
    mScriptLoadingCounter = 0,
    mScriptsLoadedCallbacks = [];

  // If script is already loaded call callback directly and return.
  if (mLoadedScripts[url] == "loadingcomplete") {
    successCallback && successCallback();
    return;
  }

  // Add script to dictionary of loaded scripts.
  mLoadedScripts[url] = "loadingstarted";
  ++mScriptLoadingCounter;

  // Create script tag.
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;

  // Bind the onload event.
  script.onload = function () {
    // Mark as loaded.
    mLoadedScripts[url] = "loadingcomplete";
    --mScriptLoadingCounter;

    // Call success callback if given.
    successCallback && successCallback();

    // Call scripts loaded callbacks if this was the last script loaded.
    if (0 == mScriptLoadingCounter) {
      for (var i = 0; i < mScriptsLoadedCallbacks.length; ++i) {
        var loadedCallback = mScriptsLoadedCallbacks[i];
        loadedCallback && loadedCallback();
      }

      // Clear callbacks - should we do this???
      mScriptsLoadedCallbacks = [];
    }
  };

  // onerror fires for things like malformed URLs and 404's.
  // If this function is called, the matching onload will not be called and
  // scriptsLoaded will not fire.
  script.onerror = function (error) {
    errorCallback && errorCallback(error);
  };

  // Attaching the script tag to the document starts loading the script.
  document.head.appendChild(script);
}
