import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageBuildTransBundle extends RoutePage {
  renderContent() {
    return html`

      <coming-soon>
        More about Building, Transpiling & Bundling (or not) in Web Standards environments to follow soon...
      </coming-soon>

      `;
  }

}
