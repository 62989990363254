import { css } from "lit";
import { CallOut } from "./call-out";

customElements.define(
  "coming-soon",
  class ComingSoon extends CallOut {
    constructor() {
      super();
      this.icon = "soon";
      this.title = "Coming soon!";
    }

    static get styles() {
      return [
        super.styles,
        css`
          [data-callout] {
            padding: 1rem
          }
        `,
      ];
    }
  }
);
