import { html } from "lit";
import { ComponentPage } from "./component-page";

export class PageTesting extends ComponentPage {
  renderContent() {
    return html`
    
    <coming-soon> More about testing in Web Standards environments to follow soon... </coming-soon>
    `;
  }
}
