import { LitElement, nothing, html } from "lit";
import { kebabToWords } from "./common";

const badgeMapper = {
  owner: "writer",
  contributor: "writer",
  partner: "checked",
  supporter: "thumb-up",
};

const colorMapper = {
  owner: "var(--color-h)",
  contributor: "lightgreen",
  partner: "coral",
};

/**
 * Fan image - based on fan handle
 */
customElements.define(
  "fan-image",
  class FanImage extends LitElement {
    static get properties() {
      return {
        handle: {
          type: String,
        },
        size: {
          type: String,
        },
      };
    }

    render() {
      if (!this.handle) return;

      const fan = app.cms.data.handle[this.handle];
      const bcol = colorMapper[fan.relation_id];

      const sizeMap = {
        large: "256px",
        medium: "190px",
        small: "64px",
        "x-small": "32px",
      };

      const size = sizeMap[this.size] || this.size || "64px";

      return html`
        <a
          title="${fan.name}
${kebabToWords(fan.relation_id ?? "")}"
          href="/profile/${this.handle}"
          ><profile-image
            size=${size ?? nothing}
            badge-icon="${badgeMapper[fan.relation_id]}"
            border-color="${bcol}"
            badge-background="${bcol}"
            badge-color="black"
            src="${fan.profileImg}"
          ></profile-image
        ></a>
      `;
    }
  }
);
