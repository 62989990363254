import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PageShowCases extends RoutePage {
  renderContent() {
    return html`
      <section>
        <p>The proof is in the pudding, or so they say...</p>
        <p>
          The showcases below are actual, working production apps that were
          created using only Web Standards, mostly following the PURE Manifesto
          Principles.
        </p>
      </section>
    `;
  }

  getChildren() {
    const children = super.getChildren();
    
    children.push({
      title: "Your case here?",
      url: "mailto:info@pureweb.dev?subject=Showcase",
      icon: "plus"
    });
    return children;
  }
}
