import { LitElement, css, html } from "lit";

function isValidDate(date) {
  return date instanceof Date && !isNaN(date.getTime());
}
/**
 * Responsive slide-out panel Container Web Component
 * Supports coolean [open] attribute for opening and closing (using Web Standard best practices).
 * Used Shadow DOM and slotting for containment.
 */
customElements.define(
  "date-input",
  class DateInput extends LitElement {
    static formAssociated = true;

    static get styles() {
      return [
        css`
          :host {
            --def-rad: 0.4rem;
            display: inline-block;
            background-color: var(--input-bg, white);
            border-radius: var(--def-rad, var(--def-rad));
          }
          [data-dateparts] {
            display: flex;
            border: 0;
            flex-direction: row;
            width: fit-content;

            .sep {
              width: 1rem;
            }

            input {
              outline: none;
              text-align: center;
              background-color: transparent;
              border: none;

              padding: 0.4rem 0.1rem;

              &:not(.sep) {
                width: 2rem;
              }

              &[name="yyyy"] {
                position: relative;
                width: 4rem;
              }
            }
          }
        `,
      ];
    }

    constructor() {
      super();
      this._internals = this.attachInternals(); // Attach internals
    }

    static get properties() {
      return {
        value: {
          type: String,
          attribute: true,
          reflect: true,
        },
      };
    }

    connectedCallback() {
      super.connectedCallback();

      this.setDate();
    }

    render() {
      const dd = this.date.getDate();
      const mm = this.date.getMonth() + 1;
      const yyyy = this.date.getFullYear();

      //console.log(dd, mm, yyyy);

      return html`
        <span data-dateparts @input=${this.userInput}>
          <input
            name="dd"
            type="text"
            pattern="[0-9]*"
            maxlength="2"
            size="2"
            placeholder="DD"
            value="${dd}"
          />
          <input
            tabindex="-1"
            size="1"
            class="sep"
            type="text"
            readonly
            value="-"
          />
          <input
            name="mm"
            type="text"
            pattern="[0-9]*"
            maxlength="2"
            placeholder="MM"
            size="2"
            value="${mm}"
          />
          <input class="sep" tabindex="-1" type="text" readonly value="-" />
          <input
            name="yyyy"
            type="text"
            pattern="[0-9]*"
            maxlength="4"
            placeholder="YYYY"
            size="4"
            maxlength="4"
            value="${yyyy}"
          />
        </span>
      `;
    }

    userInput(e) {
      e.stopPropagation();

      const inp = e.target;
      const max = e.target.maxLength;
      const dmy = this.renderRoot.querySelector("[data-dateparts]");
      const cc = dmy.children;

      if (inp.value.length >= max) {
        if (inp.nextElementSibling)
          inp.nextElementSibling.nextElementSibling.focus();
      }
      const date = new Date(
        `${Number(cc[4].value ?? "1")}-${Number(cc[2].value ?? "1")}-${Number(
          cc[0].value ?? "2000"
        )}`
      );
      console.log("raw", date);

      if (!isNaN(date.getTime())) {
        this.value = date.toISOString().split("T")[0];
        console.log(this.value);
      }
    }

    setDate() {
      this.date = new Date(this.value ?? "");
      if (!isValidDate(this.date)) this.date = new Date(2000, 1, 1);
    }
  }
);
