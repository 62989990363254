import { html } from "lit";
import { RoutePage } from "../shared/route-page";
import { matchWordStart } from "../shared/common";

export class PageBackToVanilla extends RoutePage {
  renderContent() {
    return html`
      <p>
        This section is dedicated to showcases of companies that have previously
        used frameworks but have decided to move back to embracing the native
        web.
      </p>

      <coming-soon> More about going back to vanilla to follow soon... </coming-soon>
      
    `;
  }
}
 