import { LitElement, css, html, unsafeCSS } from "lit";
import { repeat } from "lit/directives/repeat.js";

function normalizeWeights(array, minWeight, maxWeight) {
  // Find the current min and max weights in the array
  const currentMin = Math.min(...array.map((item) => item.weight));
  const currentMax = Math.max(...array.map((item) => item.weight));

  return array.map((item) => {
    return {
      ...item,
      weight: Math.round(
        ((item.weight - currentMin) / (currentMax - currentMin)) *
          (maxWeight - minWeight) +
          minWeight
      ),
    };
  });
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * Word Cloud
 */
customElements.define(
  "word-cloud",
  class FanImage extends LitElement {
    static get properties() {
      return {
        words: {
          type: Array,
        },
        max: {
          type: Number,
        },
        backgroundColor: {
          type: String,
          attribute: "background-color"
        }
      };
    }

    static get styles() {
      return [
        css`
          :host {
            display: block;
            
          }
          .cloud {

            list-style: none;
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            line-height: 2.75rem;
          }

          li {
            --size: 4;
          }

          a {
            --color: #FEDB37;
            color: var(--color);
            font-size: calc(var(--size) * 0.25rem + 0.5rem);
            display: block;
            padding: 0.125rem 0.25rem;
            position: relative;
            text-decoration: none;
          }

          ul[data-show-value] a::after {
            content: " (" attr(data-weight) ")";
            font-size: 1rem;
          }

          .cloud li:nth-child(2n + 1) a {
            --color: #dbd761;
          }
          .cloud li:nth-child(3n + 1) a {
            --color: #d7cead;
          }
          .cloud li:nth-child(4n + 1) a {
            --color: #c3b34a;
          }

          a:focus {
            outline: 1px dashed;
          }

          a::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 100%;
            background: var(--color);
            border-radius: .5rem;
            transform: translate(-50%, 0);
            opacity: 0.15;
            transition: width 0.25s;
          }

          a:focus::before,
          a:hover::before {
            width: 100%;
          }

          @media (prefers-reduced-motion) {
            .cloud * {
              transition: none !important;
            }
          }
        `,
      ];
    }

    constructor() {
      super();
      this.max = 10;
    }

    render() {
      const sortedArray = this.words.sort((a, b) => b.weight - a.weight);
      const top = shuffleArray(
        normalizeWeights(sortedArray.slice(0, this.max), 1, 10)
      );

      return html`
        <menu @click=${this.click} class="cloud" role="navigation" aria-label="Webdev word cloud">
          ${repeat(top, (item) => {
            return html`<li>
              <a style="--size:${item.weight}">${item.name}</a>
            </li>`;
          })}
        </menu>
      `;
    }

    click(e){
      if(e.target.nodeName === "A"){
        this.dispatchEvent(new CustomEvent("selectword", {
          detail: e.target.textContent.trim()
        }))
      }
    }
  }
);
