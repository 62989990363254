import { html, nothing } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageProfile extends RoutePage {
  static get properties() {
    return {
      fans: {
        type: Object,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    const handle = location.pathname.replace("/profile/", "");
    this.profile = app.cms.data.handle[handle];
    this.relatedContent = app.cms.findByProperty("handle_id", this.profile.id).map(item=>{      
      return {
        source: item._source,
        item: item
      }
    });
  }

  renderContent() {
    return html`
      <article class="profile">
        <h3>${this.profile.name}</h3>
        <blockquote>${this.profile.slogan}</blockquote>

        <section class="split">
          <a
            class="profile-link"
            target="_blank"
            rel="noopener"
            href="${this.profile.permaLink}"
            title="Visit external site"
          >
            <fan-image size="256" handle="${this.profile.id}"></fan-image>
          </a>
          <div>${this.profile.body}</div>
        </section>
          
        <a rel="noopener" target="_blank" href="${this.profile.permaLink}"
          ><svg-icon icon="link"></svg-icon> ${this.profile.permaLink}</a
        >

      </article>
    `;
  }

  renderChildren() {
  }

  getRelated(){
    return this.relatedContent
  }

}
