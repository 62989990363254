const SECONDS_PER_MINUTE = 60,
  SECONDS_PER_HOUR = 3600,
  SECONDS_PER_DAY = 86400,
  SECONDS_PER_WEEK = 604800,
  SECONDS_PER_MONTH = 30.44 * 86400,
  SECONDS_PER_YEAR = 365.25 * 86400;

/**
 *
 * @param {String} interval (years, months, days, hours, minutes)
 * @param {Number} value number
 * @returns {Date} date
 */
Date.prototype.add = function (interval, value) {
  switch (interval.toLowerCase()) {
    case "years":
      this.setFullYear(this.getFullYear() + value);
      break;
    case "months":
      this.setMonth(this.getMonth() + value);
      break;
    case "days":
      this.setDate(this.getDate() + value);
      break;
    case "hours":
      this.setTime(this.getTime() + value * 60 * 60 * 1000);
      break;
    case "minutes":
      this.setTime(this.getTime() + value * 60 * 1000);
      break;
    // Add more cases as needed
    default:
      throw new Error("Invalid interval");
  }
  return this; // Allow chaining
};

/**
 *
 * @param {Object} options
 * @returns {String} friendly date or formatted date - Using Intl.DateTimeFormat
 */
Date.prototype.format = function (options = { mode: "auto", showTime: true }) {
  return formatDateTime(this, options);
};

function formatDateTime(date, options) {
  const seconds = Math.floor((new Date() - date) / 1000);
  const direction = seconds < 0 ? -1 : 1;
  const diff = {
    second: Math.abs(seconds),
    minute: Math.abs(Math.floor(seconds / SECONDS_PER_MINUTE)),
    hour: Math.abs(Math.floor(seconds / SECONDS_PER_HOUR)),
    day: Math.abs(Math.floor(seconds / SECONDS_PER_DAY)),
    week: Math.abs(Math.floor(seconds / SECONDS_PER_WEEK)),
    month: Math.abs(Math.floor(seconds / SECONDS_PER_MONTH)),
    year: Math.abs(Math.floor(seconds / SECONDS_PER_YEAR)),
  };

  const formatDateRelativeToNow = () => {
    for (const sign of [-1, 1]) {
      for (const [period, value] of Object.entries(diff).reverse()) {
        const periodWord = value === 1 ? period : period + "s";
        const compareValue = direction < 0 ? value - 1 : value;
        if (compareValue >= 1) {
          return direction < 0
            ? `In ${compareValue} ${periodWord}`
            : `${compareValue} ${periodWord} ago`;
        }
      }
    }
  };

  options = {
    mode: "auto",
    languages: navigator.languages,
    dateStyle: "short",
    timeStyle: "short",
    ...(options ?? {}),
  };
  
  if (options.rangeHours) options.range = options.rangeHours * SECONDS_PER_HOUR;
  else if (options.rangeDays)
    options.range = options.rangeDays * SECONDS_PER_DAY;
  else options.range = 2 * SECONDS_PER_DAY;

  if (options.day) {
    delete options.dateStyle;
    delete options.timeStyle;
  }

  if (options.mode === "auto") {
    if (diff.second <= options.range) options.mode = "relative";
  }

  if (options.mode === "relative" && diff.second > options.range)
    options.mode = "format";

  if (options.mode === "relative") return formatDateRelativeToNow();

  // If today, return only the time.
  if (
    (Math.abs(diff.day) <= 1 && (!options.mode || options.mode === "auto")) ||
    options.timeOnly
  ) {
    return Intl.DateTimeFormat(navigator.languages, {
      timeStyle: "short",
    }).format(date);
  }

  try {
    delete options.mode;
    delete options.range;
    return Intl.DateTimeFormat(options.languages, options).format(date);
  } catch (ex) {
    console.error("Error formatting date:", options, ex);
    return date;
  }
}
