const iconMapper = {
  glossary: "tag",
  example: "code",
  handle: "user",
  relation: "info",
  blog: "blog",
  source: "link",
  htmlIndex: "html",
  htmlMeta: "html",
};

export class CMS extends EventTarget {
  #tags = new Map();
  #dataSources;
  #data;

  constructor() {
    super();

    this.#initialize();
  }

  fire(eventName, detail) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        composed: true,
        detail: detail ?? {},
      })
    );
  }

  on(eventName, handler) {
    this.addEventListener(eventName, handler);
    return this;
  }

  off(eventName, handler) {
    this.removeEventListener(eventName, handler);
    return this;
  }

  static async open() {
    const cms = new this();
    await cms.#initialize();
    return cms;
  }

  async #initialize() {
    const load = async (url) => {
      const path = "/cms/data/";
      const result = await import(`${path}${url}`);
      const data = result[Object.keys(result)[0]];
      return data;
    };

    const { groups, index } = await load("html-meta.js");

    const dataSources = {
      glossary: await load("glossary.js"),
      example: await load("example.js"),
      handle: await load("handle.js"),
      relation: await load("relation.js"),
      blog: await load("blog.js"),
      htmlMeta: groups,
      htmlIndex: index,
      source: await load("source.js"),
    };

    this.#data = this.createGraph(dataSources);
  }

  get data() {
    return this.#data;
  }

  findByTags(tagOrTagArray, options = { sources: null }) {
    const tags = [...(tagOrTagArray ?? "")];
    const resultMap = new Map();
    for (const tag of tags) {
      const items = this.#tags.get(tag) ?? [];
      for (const item of items) {
        if (!options?.sources || options.sources.includes(item.source))
          resultMap.set(item.itemKey, item);
      }
    }
    return Array.from(resultMap.values());
  }

  getTags(options = { filter: null }) {
    const all = [];

    for (const [key, value] of this.#tags) {
      const arr = options.filter ? value.filter(options.filter) : value;

      all.push({
        name: key,
        weight: arr.length,
      });
    }
    return all;
  }

  findByProperty(propertyName, propertyValue) {
    const ar = [];
    for (const [sourceId, data] of Object.entries(this.data)) {
      for (const [itemId, item] of Object.entries(data)) {
        if (item[propertyName] === propertyValue)
          ar.push({
            _source: sourceId,
            id: itemId,
            title: item.title ?? item.name,
            ...item,
          });
      }
    }
    return ar;
  }

  /**
   *
   * @param {String} searchText text to search for
   * @param {Array|String} sources null for all, otherwise Array of IDs of the dataSources to search.
   * @returns {Array} results of the search
   */
  find(searchText, sources = null) {
    const arr = [];
    for (const [sourceId, data] of Object.entries(this.data)) {
      if (sources === null || sources.includes(sourceId)) {
        for (const [itemId, item] of Object.entries(data)) {
          const test = JSON.stringify(item, null, " ").toLowerCase();
          if (CMS.matchWordStart(test, searchText)) {
            let text = item.title ?? item.name ?? item.id ?? item.description;
            if (text && text.length)
              arr.push({
                source: sourceId,
                text: text,
                icon: iconMapper[sourceId],
                key: itemId,
                ...item,
              });
          }
        }
      }
    }
    return arr;
  }

  list(sourceId) {
    const source = this.data[sourceId];
    return Object.entries(source).map(([key, item]) => {
      return {
        ...item,
        id: key,
        title: item.title ?? item.name ?? item.description,
      };
    });
  }

  /**
   * Matches the beginning of words in a sentence (case-insensitive)
   * @param {String} text text to search
   * @param {String} prefix of words to find
   * @returns { Boolean } true if one or more words in the text start with the prefix
   */
  static matchWordStart(text, prefix) {
    const regex = new RegExp(`\\b(${prefix})\\w*`, "gi");
    return regex.test(text);
  }

  // create tag lookup + add references to tag
  #addTags(tags = [], related) {
    for (const tag of tags) {
      let tagRefs = [];
      if (this.#tags.has(tag)) tagRefs = this.#tags.get(tag);
      else this.#tags.set(tag, tagRefs);
      tagRefs.push(related);
    }
  }

  createGraph(rawData) {
    const data = {};

    const sources = Object.entries(rawData);

    for (const page of app.config.pages) {
      if (page.config?.tags) {
        this.#addTags(page.config?.tags, {
          source: "routes",
          itemKey: page.route,
          item: page,
        });
      }
    }

    for (const [sourceId, source] of sources) {
      data[sourceId] = source;

      for (const [itemKey, item] of Object.entries(source)) {
        item.id = itemKey;

        this.#addTags(item.tags, {
          source: sourceId,
          itemKey: itemKey,
          item: item,
        });

        for (const [relatedSourceId, relatedSource] of sources) {
          if (sourceId !== relatedSourceId) {
            const handleKey = item[`${relatedSourceId}_id`];
            const detail = relatedSource[handleKey];
            if (detail) item[relatedSourceId] = detail;
          }
        }
      }
    }

    return data;
  }

  get tags() {
    return this.#tags;
  }
}
