import { RoutePage } from "../shared/route-page";
import { html } from "lit";

export class PagePWA extends RoutePage {
  static get properties() {
    return {
      pass: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.pass = "not checked";
  }

  renderTitle() {
    return html`Progressive Web Apps`;
  }

  renderContent() {
    return html`

      <p>
        The PWA is slowly but steadily becoming a real alternative for Mobile Apps.
      </p>

      <coming-soon> More about PWA development to follow soon... </coming-soon>

    `;
  }
}
