import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageEITD extends RoutePage {
  renderContent() {
    return html`
      <blockquote>
        <b>Reliance on front-end frameworks</b> can lead to a standstill, as
        resources are diverted from <del>innovation</del> to
        <ins>maintenance</ins>,
      </blockquote>

      <h3>1. Update issues</h3>
      <h3-sub>Fixing issues from framework updates</h3-sub>
      <p>
        Frameworks eventually reach an end-of-life (EOL) stage, where they no
        longer receive updates or support from the maintainers.
      </p>
      <p>
        Continuing to use an EOL framework can lead to security vulnerabilities
        and compatibility issues.
      </p>

      <p>
      Example: In 2023, the Vue.js team released Vue 3.3, which introduced several breaking changes. 
      </p>
      <p>
      Teams using Vue 2.x had to deal with issues like the removal of the this context in templates and changes to the reactivity system. 
      </p>
      <p>
      These changes required significant code refactoring and testing to ensure compatibility
      </p>

      <h3>2. End-of-life</h3>
      <h3-sub>Handling End-of-life scenarios</h3-sub>
      <p>
        Frameworks eventually reach an end-of-life (EOL) stage, where they no
        longer receive updates or support from the maintainers.
      </p>
      <p>
        Continuing to use an EOL framework can lead to security vulnerabilities
        and compatibility issues.
      </p>

      <p>
        Example: A project built on AngularJS (the original Angular) faced
        significant challenges when Google announced its end-of-life. The team
        had to migrate the entire codebase to a newer version of Angular, which
        required substantial refactoring and testing.
      </p>

      <h3>3. Conflicts</h3>
      <h3-sub>Conflicts due to necessary upgrades</h3-sub>

      <p>
        Upgrading a framework or its dependencies can sometimes lead to
        conflicts with other libraries or tools used in the project.
      </p>
      <p>
        These conflicts can cause unexpected behavior or even break the build
        process.
      </p>
      <p>
      Example: In 2024, React 18 introduced concurrent rendering, which caused conflicts with some popular libraries like React Router and Redux. 
      </p>
      <p>
       Teams had to wait for these libraries to release compatible versions or find workarounds, which delayed their upgrade process and added to their tech debt.
      </p>
    `;
  }
}
