import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PagePWFCase extends RoutePage {
  renderContent() {
    return html`
      <blockquote>
        The case of the <em>Pure Web Foundation</em> website
      </blockquote>

      <p>
        This website was built using the Pure Manifesto guidelines, and contains
        a lot of stuff that showcases modern web principles.
      </p>

      <ul class="simple-list">
        <li>
          SPA Router
          <small>The pure-web/spa router</small>
        </li>
        <li>
          State Management
          <small>Simple state management using Proxy class</small>
        </li>

        <li>UI Components
          <small>A mix of behaviorless Custom Elements, Light DOM-, and Shadow DOM Web Components</small>
        </li>

      </ul>
    `;
  }
}
