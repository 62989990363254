import { LitElement, html, css } from "lit";

/**
 * Responsive slide-out panel Container Web Component
 * Supports coolean [open] attribute for opening and closing (using Web Standard best practices).
 * Used Shadow DOM and slotting for containment.
 */
customElements.define(
  "embed-yt",
  class EmbedYouTube extends LitElement {
    static get properties() {
      return {
        code: {
          type: String,
        },
        title: {
          type: String,
        },
      };
    }

    static get styles() {
      return [
        css`
          :host {
            display: block;
          }
          iframe#youtube {
            border-radius: 1rem;
          }
        `,
      ];
    }

    render() {
      return html`
        <iframe
          id="youtube"
          width="800"
          height="480"
          src="https://www.youtube.com/embed/${this.code}"
          title="${this.title}"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      `;
    }
  }
);
