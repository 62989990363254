import { groupBy, openCenteredWindow } from "../shared/common";
import { Domain } from "../shared/domain";
import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { config } from "../pure-app.config";

export class FanDomain extends Domain {
  constructor(action) {
    super();

    switch (action) {
      case "list":
        this.getFans();
        break;
      default:
        break;
    }
  }

  static list() {
    return new this("list");
  }

  async getFans() {
    const run = new Promise((resolve) => {
      const all = Object.entries(app.cms.data.handle).map(([key, value])=>{
        return {
          id: key,
          ...value
        }
      });
      
      const featured = all.filter((i) => i.relation?.featured);

      

      const list = [
        ...featured,
        {
          name: `+${all.length - featured.length}`,
          relation_id: "fan_count",
        }
        
      ];
      resolve(list);
    });
    const featured = await run;
    this.fire("ready", featured);
  }
 
}
