import { Domain } from "../shared/domain";

export class BlogDomain extends Domain {
  getBlogs() {
    return app.cms
      .list("blog")
      .map((blog) => {
        return {
          ...blog,
          publishedDate: new Date(blog.published),
        };
      })
      .sort((a, b) => {
        if (a.publishedDate > b.publishedDate) return -1;
        else if (b.publishedDate > a.publishedDate) return 1;
        return 0;
      });
  }

  getFeaturedBlog() {
    return this.getBlogs()[0];
  }

  getBlogTags(blogs) {
    const tagMap = new Map();
    blogs.forEach((i) => {
      for (const tag of i.tags ?? []) {
        let tagRefs = [];
        if (tagMap.has(tag)) tagRefs = tagMap.get(tag);
        else tagMap.set(tag, tagRefs);
        tagRefs.push(i);
      }
    });

    const tags = [];
    for (const [key, value] of tagMap) {
      tags.push({
        name: key,
        weight: value.length,
      });
    }
    return tags;
  }
}
