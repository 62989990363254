import { LitElement, html, css } from "lit";
import { getHTML } from "./common";
import { getUniqueName } from "pure-web/common";

/**
 * Code Example Web Component for quick 
 */
customElements.define(
  "code-example",
  class EmbedSource extends LitElement {
    static get properties() {
      return {
        id: {
          type: String,
        },
        code: {
          type: Object,
        },
      };
    }

    static get styles() {
      return [
        css`
          :host {
            display: block;
          }

          iframe#embed {
            margin-top: 1rem;
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            min-height: 500px;
            background-color: transparent;
            border: 0;
          }
        `,
      ];
    }

    render() {
      let data;
      if (this.code) {
        data = this.code;
      } else {
        const options = app.cms.data.example[this.id];
        const rawHTML = getHTML(options.code?.html);
        const rawCSS = options.code?.css;

        const rawJS = (
          options.code?.js?.values
            ? options.code?.js?.values[0]
            : options.code?.js ?? ""
        ).trim();

        data = {
          html: rawHTML || null,
          css: rawCSS || null,
          js: rawJS || null,
        };
      }

      const blob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });
      const blobURL = URL.createObjectURL(blob);

      return html`<iframe
        id="embed"
        src="/run.html?key=${getUniqueName()}&source-id=${this
          .id}&data=${encodeURIComponent(blobURL)}"
      ></iframe>`;
    }
  }
);
