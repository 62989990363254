import { html } from "lit";
import { RoutePage } from "../../shared/route-page";
import { getHTML } from "../../shared/common";

const customElementExample = `<my-custom-element></my-custom-element>`;

export class PagePureManifestoCE extends RoutePage {
  renderTitle() {
    return html`${super.renderTitle()}: 4. Custom Elements`;
  }

  renderContent() {
    return html`
      <blockquote>
        Extend HTML with Custom Elements
        <br />
        ...and Think about attaching logic later on (to turn them into Web
        Components)
      </blockquote>

      <p>
        Like with many Web Standards (such as PWA — what's in a name), Web
        Components are progressive enhancements, starting with the extensibility
        of the HTML language itself, with custom tags.
      </p>

      <p>Example of a custom tag</p>
      <pre>
      <code class="language-html">${customElementExample}</code></pre>

      <blockquote>
        A custom tag is <b>not a Web Component</b>.<br />Web Components are
        custom tags <b>with attached functionality</b>.
      </blockquote>

      <p>
        A custom tag can simply be declared by using it, and using custom tags
        without defining a Web Component that attaches to it, can be a valid
        approach to HTML componentization.
      </p>

      <p>
        Sometimes it's enough to have a semantic anchor point and attach custom
        tag-specific CSS to it. Most certainly, well chosen custom tag names
        help future developers read your application flow!
      </p>

      <p>
        But when do you define a custom tag, if you only attach CSS to it (and
        no functionality)?
      </p>

      <p>My ground rules are:</p>

      <ul class="simple-list">
        <li>There's no semantic element that closely matches our goals.</li>
        <li>
          The custom tag is a wrapper that expects inner HTML following a custom
          scheme.
        </li>
      </ul>

      <call-out icon="code" title="Custom Tag (no Web Component)">
          <code-example .code=${this.ceExample}></code-example>
      </call-out>
    `;
  }

  get ceExample(){
    return {
      html: `<two-column-grid>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .
      </p>
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      </p>
</two-column-grid>`,
      css: `
        two-column-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
        }
      `
    }
  }
}
