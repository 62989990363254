import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { RoutePage } from "../../shared/route-page";
import { AutoComplete } from "pure-web/ac";
import {
  ensureRoute,
  matchWordStart,
  showcaseElement,
  throttle,
} from "../../shared/common";
export class PagePureManifestoHtml extends RoutePage {
  renderTitle() {
    return html`${super.renderTitle()}: 1. Semantic HTML`;
  }

  renderContent() {
    return html`
      <blockquote>
        Start with pure HTML (preferably semantic constructs)<br />
        ...and leverage CSS3 fully
      </blockquote>
      <p>
        Contrary to what the current leading Web App narrative is, PURE
        Manifesto prioritizes starting with pure HTML.
      </p>
      <p>
        HTML5 is extremely rich in expressing intent declaratively, and this
        seems to have been forgotten entirely, especially by the frameworks.
      </p>
      <p>
        Also, there's a lot of standard functionality defined in HTML that just
        works, and is well thought through, in terms of accessibility for
        instance.
      </p>

      <h3>Semantic Tags Advantages</h3>
      <ul class="simple-list">
        <li>
          Built-in keyboard and screen reader support enhance accessibility.
        </li>
        <li>
          Native elements are optimized by browsers, improving performance
        </li>
        <li>
          Clearly indicates focused interactions to assistive technologies
        </li>
        <li>Simplifies codebase</li>
        <li>Evolves with web standards and browser updates</li>
        <li>Better understood by search engines, improving SEO.</li>
        <li>Improves codebase readability and transferability</li>
      </ul>

      <p>
        A <code>button</code> element for instance, clearly indicates clickable
        interactions to browsers and assistive technologies, has built-in
        keyboard support, has expected focus styles, hover effects, and active
        states, enhancing the user experience, and has integrated form
        submission support
      </p>

      <p>
        A <code>dialog</code> element shows consistent behavior without extra
        libraries, includes focus trapping, modal behavior, and escape key
        dismissal, and is just easier and faster to work with
      </p>

      <hr />

      <h2>The Importance of Semantics in Web Development</h2>

      ${this.renderBenefits()}

      <h2>Semantic HTML Tag Reference</h2>

      <p>
        HTML expresses meaning, but many developers have too little insight in
        what to use where
      </p>

      <form action="#" method="post">
        <label>
          <span data-label>Filter tags (name, function)</span>
          <input
            type="search"
            placeholder="Start typing to find elements by name of function..."
            name="filter"
            @input=${throttle(this.filterSemanticTags.bind(this), 200)}
          />
        </label>
      </form>

      ${this.renderSemanticElementGlossary()}
    `;
  }

  filterSemanticTags(e) {
    const text = e.target.value;
    if (text.length >= 1) {
      const groups = document.querySelectorAll("#semantic-elements li details");

      for (const group of groups) {
        group.open = false;

        const all = group.querySelectorAll("li");

        for (const li of all) {
          const elementName = li.querySelector("span").textContent;
          const description = li.getAttribute("title");
          if (
            matchWordStart(elementName, text) ||
            (text.length > 2 && matchWordStart(description, text))
          ) {
            showcaseElement(li, {
              highlightTimeoutMs: 10000,
            });
          }
        }
      }
    }
  }

  renderSemanticElementGlossary() {
    const htmlMeta = app.cms.data.htmlMeta;
    const htmlIndex = app.cms.data.htmlIndex;

    return html`<ul id="semantic-elements" class="accordion">
      ${repeat(Object.entries(htmlMeta), ([groupName, groupData], index) => {
        if (groupName !== "fullSlug")
          return this.renderSemanticElementGroup(
            htmlIndex,
            groupName,
            groupData,
            index
          );
      })}
    </ul> `;
  }

  renderSemanticElementGroup(glossary, groupName, groupData, index) {
    return html`<li>
      <details ?open=${index === 0}>
        <summary>${groupName}</summary>
        <ul class="badges outline">
          ${repeat(Object.entries(groupData), ([gk, data]) => {
            const key = gk + " element";
            const merged = { ...glossary[key], ...data };
            return html`<li data-tag="${key}" title="${merged.description}">
              <span>${gk}</span>
            </li>`;
          })}
        </ul>
      </details>
    </li>`;
  }

  renderBenefits() {
    return html`
      <div>
        <p>
          Semantic HTML tags are crucial in web development for several
          important reasons:
        </p>

        <ul class="accordion">
          <li>
            <details>
              <summary>📖 Improved Readability and Maintainability</summary>

              <ul>
                <li>
                  <p>
                    They clearly define the purpose and structure of different
                    elements on a webpage.
                  </p>
                </li>
                <li>
                  <p>
                    Using semantic tags instead of generic &lt;div&gt; elements
                    improves code organization and self-documentation.
                  </p>
                </li>
                <li>
                  <p>
                    It becomes easier for developers to understand the structure
                    and purpose of the HTML without needing additional comments.
                  </p>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>🦯 Enhanced Accessibility</summary>

              <ul>
                <li>
                  <p>
                    Screen readers and other assistive technologies can better
                    interpret the content structure.
                  </p>
                </li>
                <li>
                  <p>
                    It helps users with disabilities navigate and understand web
                    pages more easily.
                  </p>
                </li>
                <li>
                  <p>
                    Semantic tags provide clear semantic meaning, enhancing the
                    overall accessibility of the website.
                  </p>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>🔍 Better SEO Performance</summary>

              <ul>
                <li>
                  <p>
                    Search engines can more easily understand the structure and
                    importance of page content.
                  </p>
                </li>
                <li>
                  <p>
                    Properly structured semantic HTML allows search engines to
                    rank pages more accurately.
                  </p>
                </li>
                <li>
                  <p>
                    Keywords placed in appropriate semantic tags (e.g.,
                    &lt;h1&gt;, &lt;header&gt;) carry more weight in search
                    algorithms.
                  </p>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>🌐 Improved Browser Interpretation</summary>

              <ul>
                <li>
                  <p>
                    Browsers can better understand the purpose and context of
                    different elements.
                  </p>
                </li>
                <li>
                  <p>
                    This leads to faster rendering and improved performance
                    across devices.
                  </p>
                </li>
                <li>
                  <p>
                    Semantic tags allow browsers to apply appropriate styles and
                    behaviors automatically.
                  </p>
                </li>
              </ul>
            </details>
          </li>

          <li>
            <details>
              <summary>💡 Consistency Across Websites</summary>

              <ul>
                <li>
                  <p>
                    It follows standardized naming conventions for different
                    elements.
                  </p>
                </li>
                <li>
                  <p>
                    This consistency aids in creating more interoperable web
                    pages.
                  </p>
                </li>
                <li>
                  <p>
                    It contributes to a more uniform browsing experience across
                    the internet.
                  </p>
                </li>
              </ul>
            </details>
          </li>

          <li></li>

          <li>
            <details>
              <summary>🟢 Future-Proofing</summary>

              <ul>
                <li>
                  <p>
                    As HTML evolves, semantic tags are more likely to remain
                    relevant and useful.
                  </p>
                </li>
                <li>
                  <p>
                    Developers who master semantic HTML today will find it
                    easier to adapt to future standards.
                  </p>
                </li>
                <li>
                  <p>
                    It aligns with the ongoing trend towards more meaningful and
                    accessible web design.
                  </p>
                </li>
              </ul>
            </details>
          </li>

          <li></li>
        </ul>
      </div>
    `;
  }
}
