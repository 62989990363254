import { html } from "lit";
import { ComponentPage } from "./component-page";

export class PageFormHandling extends ComponentPage {
  renderContent() {
    return html`
      <p>
        Ideally, you would like to stray away from the standards as little as
        possible, especially when it comes to more complex form handling.
      </p>
      <p>
        Frameworks have done exactly the opposite: each existing framework has
        very elaborate ways of handling the very heart of the interactive web in
        completely proprietary ways.
      </p>

      <blockquote>
        We'll show you that you can get all you want with semantic, well-used
        HTML, a bit of progressive enhancement, some custom elements, a dice of
        CSS and only in very specific cases, more complex, well-written,
        accessible,
        <dfn data-tag="element-internals">first-class form citizen</dfn> (Shadow
        DOM) Web components that encapsulate
      </blockquote>

      <coming-soon> More about form handling to follow soon... </coming-soon>
    `;
  }
}
