import { html } from "lit";
import { ComponentPage } from "./component-page";

export class PageUIComponents extends ComponentPage {
  renderTitle() {
    return this.options.config.title ?? this.options.name;
  }

  renderContent() {
    return html`
      <p>
        When you come from a Framework environment, you're spoiled with the
        large ecosystem most have, where you have large UI component libraries
        such as Material UI (MUI), Ant Design, Vuetify, etc.
      </p>

      <p>
        Now, when you enter the land of the Web Standards, are you then 'on your
        own'?
      </p>

      <p>Of course not.</p>

      <p>Here are some great choices:</p>

      <ul class="simple-list">
        <li>
          <a
            href="https://m3.material.io/develop/web"
            rel="noopener"
            target="_blank"
            >Material Web</a
          >
          <small>
            The original Google Material UI components, avaliable as Web
            Components.
          </small>
        </li>

        <li>
          <a href="https://shoelace.style/" rel="noopener" target="_blank"
            >Shoelace</a
          >
          <small>
            A collection of professionally designed, framework-agnostic UI
            components built with Web Components.
          </small>
        </li>
        <li>
          <a href="https://vaadin.com/components" rel="noopener" target="_blank"
            >Vaadin</a
          >
          <small>
            Provides a set of UI components for building modern web
            applications, all implemented as Web Components.
          </small>
        </li>

        <li>
          <a href="https://fast.design/" rel="noopener" target="_blank">FAST</a>
          <small>
            A set of Web Components that work with any framework or no framework
            at all, developed by Microsoft.
          </small>
        </li>

        <li>
          <a
            href="https://sap.github.io/ui5-webcomponents/"
            rel="noopener"
            target="_blank"
            >UI5 Web Components</a
          >
          <small>
            A library of reusable UI components that follow the SAP Fiori design
            guidelines.
          </small>
        </li>

        <li>
          <a
            href="https://component.kitchen/elix"
            rel="noopener"
            target="_blank"
            >Elix</a
          >
          <small>
            A community-driven collection of high-quality, reusable Web
            Components
          </small>
        </li>

        <li>
          <a href="https://lion-web.netlify.app/" rel="noopener" target="_blank"
            >Lion</a
          >
          <small>
            A set of highly performant, accessible, and flexible Web Components
            developed by ING
          </small>
        </li>
      </ul>
    `;
  }
}
