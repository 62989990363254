import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageExecutiveSummary extends RoutePage {
  renderContent() {
    return html`<p>
        Over the past decade, front-end development has been heavily influenced
        by frameworks like React, leading to a misconception that professional
        web development is not feasible without them. The <b>pressure to adhere to
        these perceived industry standards</b> is significant, and deviating from
        popular options is often seen as risky.
      </p>

      <p>
        However, selecting a framework inherently generates 
        externally-inflicted technical debt due to dependencies on third-party components. 
        These dependencies require frequent updates and upgrades, which can
        <b>disrupt and add costs to our software development process</b>.
      </p>
      <blockquote>
        Tech Leadership should realize that, in the long term <b>the reliance on front-end frameworks</b> can lead to a standstill, as resources
        are diverted from <del>innovation</del> to <ins>maintenance</ins>, fixing issues from
        framework updates, end-of-life scenarios, or conflicts due to necessary
        upgrades.
      </blockquote>

      <p>
        Therefore, it is crucial to make informed decisions about our frontend
        frameworks and tech stacks. These choices will significantly impact our
        software's sustainability, maintainability, and overall success in the
        long term. Prioritizing <b>web standards</b> over third-party dependencies can
        <b>mitigate these risks</b>, ensuring a more stable and <b>future-proof</b>
        development environment.
      </p>

      <h3>The Case for Web Standards</h3>

      <p>
        To mitigate these risks, I propose a strategic shift towards adhering to
        web standards. Here are the compelling reasons for this approach:
      </p>

      <ul class="sumup fly-in">
        <li>
          <b>Stability and Longevity</b>:
          <p>
            Web standards, such as HTML, CSS, and vanilla JavaScript, are
            maintained by international bodies like the W3C and WHATWG. These
            standards evolve gradually and predictably, ensuring long-term
            stability and compatibility across different browsers and platforms.
          </p>
        </li>
        <li>
          <b>Reduced Technical Debt</b>:
          <p>
            By minimizing reliance on third-party frameworks, we can
            significantly reduce technical debt. Web standards are less likely
            to undergo disruptive changes, allowing us to maintain a more stable
            codebase with fewer unexpected issues.
          </p>
        </li>
        <li>
          <b>Interoperability</b>:
          <p>
            Sticking to web standards ensures that our applications remain
            <b>interoperable</b> with a wide range of devices and environments. This
            broad compatibility enhances user experience and accessibility,
            reaching a larger audience without additional development overhead.
          </p>
        </li>
        <li>
          <b>Performance and Optimization</b>:
          <p>
            Web standards are <b>optimized for performance and efficiency</b>. By
            leveraging these standards, we can create <b>lightweight</b>, fast-loading
            applications that provide a seamless user experience, even on
            lower-end devices and slower networks.
          </p>
        </li>
        <li>
          <b>Future-Proofing</b>:
          <p>
            As web standards are widely adopted and supported, our applications
            will be better positioned to integrate with emerging technologies
            and trends. This future-proofing ensures that our software remains
            relevant and adaptable in a rapidly changing tech landscape.
          </p>
        </li>
      </ul>`;
  }
}
