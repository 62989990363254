const defaultKeyGenerator = (target) => {
  return `${target.nodeName}#${target.id}`;
};

/**
 * Generic element visibility Observer.
 *
 * Observes the visibility of elements matching a selector dynamically.
 * - uses MutationObserver to track dynamically modified DOM
 * - tracks scrolling in and out of view
 * - detects visibility by looking at display, and parent state (such as open in a details tag)
 *
 * @event update has an array of current visible elements in the event detail.
 */
export class VisibilityObserver extends EventTarget {
  #lastCheckTime = Date.now();

  constructor(rootElement, selector, generateKey = defaultKeyGenerator) {
    super();

    this.generateKey = generateKey;

    if (!rootElement) throw Error("No root element passed");
    this.rootElement = rootElement;

    if (!selector) throw Error("No selector passed");
    this.selector = selector;
  }

  observe() {
    const observeVisibility = () => {
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList") {
            this.handleVisibilityCheck();
          } else if (mutation.type === "attributes") {
            if (mutation.attributeName !== "class")
              this.handleVisibilityCheck();
          }
        });
      });

      this.observer.observe(this.rootElement, {
        childList: true,
        subtree: true,
        attributes: true,
      });

      return this.observer;
    };

    const run = () => {
      const handleScroll = () => {
        requestAnimationFrame(() => {
          const now = Date.now();
          if (now - this.#lastCheckTime > 500) {
            this.handleVisibilityCheck();
            this.#lastCheckTime = now;
          }
        });
      };

      let scrollContainer = getClosestScrollContainer(this.rootElement);

      scrollContainer.addEventListener("scroll", handleScroll, {
        passive: true,
      });

      setTimeout(() => {
        const observer = observeVisibility();
      }, 50);

      setTimeout(() => {
        this.handleVisibilityCheck();
      }, 500);
    };

    run();
  }

  handleVisibilityCheck() {
    const visibleElements = {};
    const all = this.rootElement.querySelectorAll(this.selector);
    //console.log("handleVisibilityCheck", all.length , "elements found")
    if (all.length > 0) {
      for (const taggedElement of all) {
        if (VisibilityObserver.isVisible(taggedElement)) {
          const key = this.generateKey(taggedElement);

          visibleElements[key] = taggedElement;
        }
      }
      const elementsArray = Object.values(visibleElements);

      if (elementsArray.length > 0)
        this.dispatchEvent(
          new CustomEvent("update", {
            detail: elementsArray,
          })
        );
    }
  }

  on(eventName, fn) {
    this.addEventListener(eventName, fn);
    return this;
  }

  destroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  static isVisible(element, container = null) {
    if (!element) return false;

    const isIntersecting = (rect1, rect2) => {
      return !(
        rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom
      );
    };

    // Check if element is hidden via CSS
    const style = window.getComputedStyle(element);
    if (style.display === "none" || style.visibility === "hidden") return false;

    // Check if element is inside a collapsed <details>
    let parent = element.parentElement;
    while (parent) {
      if (parent.tagName === "DETAILS" && !parent.open) return false;
      parent = parent.parentElement;
    }

    // Get the container's bounding rectangle
    const containerRect = container ? container.getBoundingClientRect() : null;

    // Get the element's bounding rectangle relative to the container
    const elementRect = element.getBoundingClientRect();

    // Check if the element is within the container's bounds
    if (container && !isIntersecting(containerRect, elementRect)) return false;

    // Check if the element is within the viewport bounds
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return (
      elementRect.top < viewportHeight &&
      elementRect.bottom > 0 &&
      elementRect.left < viewportWidth &&
      elementRect.right > 0
    );
  }
}

export function getClosestScrollContainer(element) {
  if (!(element instanceof Element)) {
    throw new Error("Argument must be an HTML element");
  }

  function isScrollable(style) {
    return /auto|scroll|hidden/.test(
      style.overflow + style.overflowY + style.overflowX
    );
  }

  let current = element;
  while (current) {
    const style = getComputedStyle(current);
    if (isScrollable(style)) {
      return current.nodeName === "BODY" ? window : current;
    }
    current = current.parentElement;
  }

  return window;
}
