import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PagePureManifestoLD extends RoutePage {
  renderTitle() {
    return html`${super.renderTitle()}: 3. Light DOM`;
  }

  renderContent() {
    return html`
      <blockquote>
        Prefer Light DOM
        <br />...before even thinking about Shadow DOM
      </blockquote>

      <p>
        When you do need functionality that is triggered when a custom tag is
        used, you have to attach that functionality, using
        <code class="language-js">customElements.define()</code>.
      </p>

      <p>
        In many cases, you may want to leverage global styling, or just need a
        container-type element that has JavaScript functionality that acts as an
        app container (handling global state, catching bubbling events, etc.).
      </p>

      <p>
        In that case, you don't want to completely isolate the inner workings of
        your component.
      </p>

      <p>
        It's really simple to use Shadow DOM in a Web Component, but the
        consequences of doing so are not for the faint-hearted.
      </p>

      <p>
        There's a world of complexity lying behind Shadow DOM, and although
        using it definitely has big advantages, there are many use cases that
        don't need it, and in fact, are better off without it.
      </p>

      <call-out icon="code" title="Range input to Social Rating control">
        <code-example .code=${this.lightDOMExample}></code-example>
      </call-out>
    `;
  }

  get lightDOMExample() {
    return {
      html: `
    <range-rating>
      <input id="stars" value="3" type="range" min="1" max="5" step="1" name="stars" />
      <output for="stars"></output>
    </range-rating>
  `,
      css: `/* range-rating web component */
range-rating {
  display: block;
  position: relative;

  input[type="range"] {
    appearance: none;
    display: block;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--color-surface-100, #333);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &::-webkit-slider-thumb {
      height: 28px;
      margin-top: 10px;
      opacity: 0;
    }

    &:focus {
      border-bottom-color: var(--color-accent, rgb(254, 219, 55));
    }
  }

  output {
    position: relative;
    font-size: 28px;
    color: transparent;

    &::after {
      pointer-events: none;
      letter-spacing: 3px;
      display: block;
      position: absolute;
      content: attr(data-stars);
      color: var(--color-accent, #feab07);
      top: -37px;
      left: 10px;
    }
  }
}
`,
      js: `customElements.define(
  "range-rating",

  class RangeRating extends HTMLElement {
    connectedCallback() {
      const range = this.querySelector('input[type="range"]');
      const output = this.querySelector("output");
      const update = (value) => {
        range.setAttribute("value", value);
        output.value = value;
        output.setAttribute("data-stars", "★".repeat(parseInt(value)));
      };
      range.addEventListener("input", (e) => {
        update(e.target.value);
      });
      update(range.value);
    }
  }
);`,
    };
  }
}
