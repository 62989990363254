import { html } from "lit";
import { ComponentPage } from "./component-page";

const proxy = {
  html: `<reactive-component></reactive-component>`,

  js: `class ReactiveComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.state = new Proxy({ count: 0 }, this.stateHandler());
    }

    stateHandler() {
        return {
            set: (target, property, value) => {
                target[property] = value;
                this.render();
                return true;
            }
        };
    }

    connectedCallback() {
        this.shadowRoot.innerHTML = \`

          <style>
          button {
            padding: .5rem;
            border-radius: .5rem;
            cursor: pointer 
          }
          </style>

          <div id="root"></div>
          <button id="increment">Increment</button>
        \`;

        this.shadowRoot.getElementById('increment').addEventListener('click', () => {
            this.state.count += 1;
        });
        this.render();
    }

    render() {
        this.shadowRoot.getElementById('root').innerHTML = \`<p>Count: \${this.state.count}</p>\`;
    }
}

customElements.define('reactive-component', ReactiveComponent);
`,
};

export class PageDataBinding extends ComponentPage {
  renderContent() {
    return html`
      <blockquote>
        Data binding is not some magic that is exclusively handled by frontend
        frameworks!
      </blockquote>

      <h3>1. Using Lit</h3>

      <p>
        Lit is known for its extremely easy to use reactive data binding built
        in, using statically defined properties that are being observed.
      </p>
      <p>
        See
        <a
          rel="noopener"
          target="_blank"
          href="https://lit.dev/docs/components/properties/"
          >Lit - Reactive properties</a
        >
      </p>

      <h3>2. Using 100% native, no-dependencies code</h3>

      <p>
        You don't need Lit (or other libraries) to do reactive rendering. It's
        quite simple and requires just a bit of knowledge of lesser known
        built-in JavaScript classes like <code>Proxy</code> to get the basics
        working, as we can see in the example below.
      </p>

      <call-out icon="code" title="Native reactiveness">
        <code-example .code=${proxy}></code-example>
      </call-out>

      <coming-soon>
        More about data binding in Web Standards environments to follow soon...
      </coming-soon>
    `;
  }
}
