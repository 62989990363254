import { LitElement, css, html } from "lit";

/**
 * Profile Image element
 */
customElements.define(
  "profile-image",
  class ProfileImage extends LitElement {
    static get styles() {
      return [
        css`
          figure {
            position: relative;
            display: inline-block;
            padding: 0;
            margin: 0;
          }
          img {
            height: var(--profile-image-size, 32px);
            border-radius: 100%;
            aspect-ratio: 1/1;
            background-color: var(--profile-image-bg, white);
            border: 2px solid var(--profile-image-border, transparent);
          }
          .badge {
            display: inline-block;
            position: absolute;
            right: -4px;
            bottom: -4px;
            border-radius: 100%;
            aspect-ratio: 1/1;
            background-color: var(--profile-image-badge-bg, white);
            padding: 2px;
            box-shadow: var(--profile-image-badge-shadow);
          }
          svg-icon {
            --icon-fill-color: black;
            --icon-size: var(--profile-badge-size, 16px);
          }
        `,
      ];
    }

    static get properties() {
      return {
        src: {
          type: String,
        },
        size: {
          type: Number,
        },
        badgeIcon: {
          type: String,
          attribute: "badge-icon",
        },
        badgeColor: {
          type: String,
          attribute: "badge-color",
        },
        badgeBackground: {
          type: String,
          attribute: "badge-background",
        },
        borderColor: {
          type: String,
          attribute: "border-color",
        },
      };
    }

    render() {
      return html`
        <figure
          style="${this.borderColor
            ? `--profile-image-border: ${this.borderColor}`
            : ""}; ${this.size
            ? `--profile-image-size: ${this.size}px; --profile-badge-size: ${
                this.size / 2.5
              }px`
            : ""}"
        >
          <img alt="" loading="lazy" @load=${this.ready} src="${this.src}" />
          ${this.renderBadge()}
        </figure>
      `;
    }

    ready(e) {
      this.renderRoot.querySelector("figure").classList.add("ready");
    }

    renderBadge() {
      if (!this.badgeIcon) return;

      return html` <span
        class="badge"
        style="${this.badgeBackground
          ? `--profile-image-badge-bg: ${this.badgeBackground}`
          : ""}"
      >
        <svg-icon
          color="${this.badgeColor}"
          icon="${this.badgeIcon}"
        ></svg-icon>
      </span>`;
    }
  }
);
